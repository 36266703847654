import React, { useState } from 'react'
import { AiOutlineQrcode } from 'react-icons/ai'
import logo from '../../assets/conexo.png'
import { MdClose } from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";
import './PageInfoSection.css'

interface PageInfoProps {
    pageTitle: string
    infoTitle: string,
    infoText1: string,
    infoText2?: string,
    logoText?: string
}

function PageInfoSection({ pageTitle, infoTitle, logoText, infoText1, infoText2 }: PageInfoProps) {
    const [openInfo, setOpenInfo] = useState(false)
    return (
        <div className="">
            <div className="page_title_wrapper">
                <h4 className="box_title">
                    {pageTitle}
                </h4>
                {!openInfo && < FaInfoCircle
                    data-testid='info-icon'
                    onClick={() => setOpenInfo(!openInfo)}
                    className='info_icon_1' />}
            </div>
            {openInfo && <div className="id_image_text">
                <div className="icon_logo">
                    <AiOutlineQrcode className='id_icon' />
                    <img className="id_logo" src={logo} alt="conexo-logo" />
                    {logoText && <p className='id_icon_text'>  {logoText}</p>}
                </div>
                <div className="id_text_part">
                    <div className="page_title_wrapper">
                        <h4
                            data-testid='info-title'
                            className="box_text_title">
                            {infoTitle}
                        </h4>
                        < MdClose
                            data-testid='close-icon'
                            onClick={() => setOpenInfo(!openInfo)}
                            className='info_icon_2' />
                    </div>
                    <p className="id_text">
                        {infoText1}
                    </p>
                    {infoText2 && <p className="id_text">
                        {infoText2}
                    </p>}
                </div>
            </div>}
        </div>
    )
}

export default PageInfoSection
