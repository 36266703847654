import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import CustomModal from '../../components/customModal/CustomModal';
import AppHeader from '../../components/header/AppHeader';
import InputPart from '../../components/inputPart/InputPart';
import { GeneralTexts, ManufacturerPage, UpdateManufacturerPage } from '../../language/Texts';
import { IManufacturerItem } from '../../models/IManufacturerList';
import { Paths } from '../../models/Paths';
import { RetrofitManufacturer } from '../../models/RetrofitManufacturer';
import ButtonGroup from './ButtonGroup';
import './Overview.css';
import UserList from './UserList';
import OverviewImage from '../../components/overviewImage/OverviewImage';

function Overview({ getManufacturerList }) {
  const [modifyData, setmodifyData] = useState<IManufacturerItem>();
  const { state } = useLocation() as any;
  const [isModalOpen, setIsModalOpen] = useState(false);
  let navigate = useNavigate()
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }

  // If the page is refreshed, useLocation is deleted. That's why it is saved under sessionstorage.
  if (state) {
    sessionStorage.setItem("manufacturerItem", JSON.stringify(state))
  }

  const manufacturerItem = state ?? JSON.parse(sessionStorage.getItem("manufacturerItem")!)
  const { trusted_manufacturer_id, trusted_manufacturer_name, counter, basis_url, image, imagePath } = state ? state : JSON.parse(sessionStorage.getItem("manufacturerItem")!);

  // Retrofit manufacturer should not be changed. That's why delete and edit buttons are not showed in this case
  const isRetrofitManufacturer = manufacturerItem.trusted_manufacturer_id === RetrofitManufacturer.id

  return (
    <div className='overview_wrapper'>
      <AppHeader />
      <div className="overview_title">
        <h4 className="box_title">
          {trusted_manufacturer_name}
        </h4>
        {!isRetrofitManufacturer ?
          <ButtonGroup
            isOnlyIcon={true}
            toggleModal={toggleModal}
            setmodifyData={setmodifyData}
            manufacturerItem={manufacturerItem}
          /> :
          <Button
            onClick={() => navigate(Paths.trustedmanufacturers)}
            variant="primary"
            className="cancel_button general_button"> {GeneralTexts.cancel}
          </Button>
        }
      </div>
      <div className="overview_main">
        <div className="overview_inpuat_group">
          <InputPart labelText={ManufacturerPage.manufacturerName} type="text" value={trusted_manufacturer_name} disabled={true} required={true} />
          <InputPart labelText={UpdateManufacturerPage.manufacturerId} type="text" value={trusted_manufacturer_id} disabled={true} required={true} />
          <InputPart labelText={ManufacturerPage.counter} type="text" value={counter} disabled={true} required={true} />
          <InputPart labelText={UpdateManufacturerPage.baseUrl} type="url" value={basis_url} disabled={true} required={true} onChange={() => null} />
          {imagePath && <OverviewImage labelText={UpdateManufacturerPage.image} image={image} imagePath={imagePath} />}
        </div>
        
        {!isRetrofitManufacturer &&
          <UserList
            userList={manufacturerItem.user}
          />
        }
      </div>

      {!isRetrofitManufacturer &&
        <ButtonGroup
          isOnlyIcon={false}
          toggleModal={toggleModal}
          setmodifyData={setmodifyData}
          manufacturerItem={manufacturerItem}
        />
      }
      <CustomModal
        toggleModal={toggleModal}
        isModalOpen={isModalOpen}
        modifyData={modifyData!}
        getManufacturerList={getManufacturerList}
      />

    </div >
  )
}

export default Overview
