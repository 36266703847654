import Select from 'react-select';
import DownloadOptions from '../../components/downloadOptions/DownloadOptions';
import { GeneralTexts, IdLinkCreatePage, ManufacturerPage } from '../../language/Texts';
import { IDownloadOptions } from '../../models/IDownloadOptions';
import { IManufacturerItem } from '../../models/IManufacturerList';
import { IManufacturerOption } from '../../models/IManufacturerOption';
import { Utils } from '../../utils/Utils';
import CustomTextInput from '../../components/customTextInput/CustomTextInput';

interface InputPartProps {
    filteredManufacturerList: IManufacturerItem[] | undefined,
    setManufacturer: (item: IManufacturerItem | null) => void,
    setQuantity: (quantity: string) => void,
    customText: string,
    setCustomText: (value: string) => void,
    manufacturerOption: IManufacturerOption | null,
    setManufacturerOption: (optionsValue: IManufacturerOption | null) => void,
    downloadOptions: IDownloadOptions,
    setDownloadOptions: (options: IDownloadOptions) => void,
    pdfDimensions: { pdfWidth: number, pdfHeight: number },
    setPdfDimensions: ({ pdfWidth, pdfHeight }) => void,
    quantity: string,
    disabled: boolean
}
function IdLinkInputPart({
    filteredManufacturerList,
    setManufacturer,
    quantity, setQuantity,
    manufacturerOption,
    setManufacturerOption,
    downloadOptions, setDownloadOptions,
    customText, setCustomText,
    pdfDimensions, setPdfDimensions,
    disabled }: InputPartProps) {

    // Filtered manufacturer list is sorted alphabetically and mapped to options
    let options = filteredManufacturerList?.
        sort((a, b) =>
            (a["trusted_manufacturer_name"].toUpperCase() > b["trusted_manufacturer_name"].toUpperCase()) ? 1
                : ((b["trusted_manufacturer_name"].toUpperCase() > a["trusted_manufacturer_name"].toUpperCase()) ? -1 : 0)).
        map(item => {
            return { value: item, label: item.trusted_manufacturer_name }
        })

    const onSelect = (selectedItem) => {
        if (selectedItem) {
            setManufacturer(selectedItem.value)
            setManufacturerOption(selectedItem)
        }
        else {
            setManufacturer(null)
            setManufacturerOption(null)
        }
    }

    const setIdQuantity = (quantity: string) => {
        setQuantity(quantity)
    }

    const handleDownloadOptions = (option: string) => {
        Utils.handleDownloadOptions(option, downloadOptions, setDownloadOptions)
    }

    const handleCustomTextInput = (value: string) => {
        setCustomText(value)
    }

    const style = {
        control: base => ({
            ...base,
            margin: "5px",
            borderRadius: "0",
            boxShadow: "0 0.7px black",
            border: 0,
        })
    };

    return (
        <div className="id_input_part">
            <div className="id_label" >
                <label className='id_label'>{ManufacturerPage.manufacturerName}</label>
                <Select
                    value={manufacturerOption}
                    styles={style}
                    isClearable
                    onChange={(e) => onSelect(e)}
                    placeholder={IdLinkCreatePage.chooseManufacturer}
                    options={options}
                    className="manufacturer_select"
                    isDisabled={disabled}
                />
            </div>
            <div className="id_label">
                <label className='id_label'>{GeneralTexts.quantity}</label>
                <input
                    data-testid="quantity"
                    placeholder={GeneralTexts.quantity}
                    className='id_number_input'
                    type={"number"}
                    inputMode="numeric"
                    value={quantity}
                    onChange={(e) => setIdQuantity(e.target.value)}
                    disabled={disabled}
                />
            </div>

            < CustomTextInput
                customText={customText}
                handleCustomTextInput={handleCustomTextInput}
                disabled={disabled}
            />

            <DownloadOptions
                handleDownloadOptions={handleDownloadOptions}
                downloadOptions={downloadOptions}
                pdfDimensions={pdfDimensions}
                setPdfDimensions={setPdfDimensions}
                disabled={disabled} />
        </div>
    )
}

export default IdLinkInputPart
