import { GeneralTexts } from "../language/Texts"
import { ContentTypes } from "../models/ContentTypes"
import { IFailOptProps } from "../models/IFailOptProps"
import { ISuccessOptProps } from "../models/ISuccessOptProps"
import { downloadFileOnBrowser } from "./DownloadFile"

export const setSuccessOptions = async (props: ISuccessOptProps) => {
    const { serverResponse, fileType, setAlertOpt, alertOpt, downloadFile } = props
    if (serverResponse.data.type === ContentTypes.ZIP || serverResponse.data.type === ContentTypes.PDF || serverResponse.data.type === ContentTypes.EXCEL) {
        downloadFileOnBrowser(serverResponse, fileType)
        setAlertOpt({ isOpen: true, message: GeneralTexts.successfullyDowndloaded, variant: "success" })
        setTimeout(() => {
            setAlertOpt({ isOpen: false, message: '', variant: alertOpt.variant })
        }, 3000)
    } else if (serverResponse.data.type === "application/json") {
        const { message } = JSON.parse(await serverResponse.data.text())
        if (message === "busy") {
            setTimeout(() => {
                downloadFile(fileType)
            }, 5000)
        } else {
            console.error('message :', message);
            const failOptProps = { error:GeneralTexts.sthWrong, option: 'zipFile', setAlertOpt, alertOpt}
            setFailOptions(failOptProps)
        }
    } else {
        const failOptProps = { error:GeneralTexts.sthWrong, option: 'zipFile', setAlertOpt, alertOpt}
        setFailOptions(failOptProps)
    }
}

export const setFailOptions = (props: IFailOptProps) => {
    const { error, option, setAlertOpt, alertOpt } = props
    if (error === "IdLink or componentId is not exist on DB") {
        setAlertOpt({ isOpen: true, message: GeneralTexts.idNotExistInDB, variant: 'danger' })
    } else {
        setAlertOpt({ isOpen: true, message: GeneralTexts.failedToDownload, variant: 'danger' })
        console.log(`error on getting ${option}:`, error);
    }
    setTimeout(() => {
        setAlertOpt({ isOpen: false, message: '', variant: alertOpt.variant })
    }, 3000)
}