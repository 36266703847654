import React, { useEffect } from 'react'
import { Pagination } from "react-headless-pagination";
import { useGlobalContext } from "../../utils/GlobalContext"
import { faAnglesLeft, faAnglesRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./PaginationComponent.css"

interface PaginationProps {
    contextVariables: {
        totalHistoryPages: number,
        totalManufacturerPages: number,
        setManufacturerPage: (page: number) => void,
        manufacturerPage: number,
        historyPage: number,
        setHistoryPage: (page: number) => void
    }
    isHistory?: boolean
}
function PaginationComponent({ isHistory, contextVariables }: PaginationProps) {
    const { totalManufacturerPages,
        setManufacturerPage,
        manufacturerPage,
        totalHistoryPages,
        historyPage,
        setHistoryPage } = contextVariables

    useEffect(() => {
        setManufacturerPage(0)
        setHistoryPage(0)
    }, [totalManufacturerPages, totalHistoryPages])

    const handlePageChange = (page: number) => {
        if (isHistory) {
            setHistoryPage(page)
        } else {
            setManufacturerPage(page);
        }
    };

    const handleNextButton = () => {
        if (isHistory) {
            setHistoryPage(totalHistoryPages - 1)
        } else {
            setManufacturerPage(totalManufacturerPages - 1);
        }
    }

    const handlePreviousButton = () => {
        if (isHistory) {
            setHistoryPage(0)
        } else {
            setManufacturerPage(0)
        }
    }

    const getPreviousClassName = () => {
        if (isHistory) {
            return historyPage === 0 ? "double_arrow inactiv" : "double_arrow"
        } else {
            return manufacturerPage === 0 ? "double_arrow inactiv" : "double_arrow"
        }
    }

    const getNextClassName = () => {
        if (isHistory) {
            return historyPage + 1 === totalHistoryPages ? "double_arrow inactiv" : "double_arrow"
        } else {
            return manufacturerPage + 1 === totalManufacturerPages ? "double_arrow inactiv" : "double_arrow"
        }
    }

    const previous = <FontAwesomeIcon icon={faAngleLeft} />
    const previous2 = <FontAwesomeIcon icon={faAnglesLeft} />
    const next = <FontAwesomeIcon icon={faAngleRight} />
    const next2 = <FontAwesomeIcon icon={faAnglesRight} />


    return (
        <Pagination
            currentPage={isHistory ? historyPage : manufacturerPage}
            setCurrentPage={handlePageChange}
            totalPages={isHistory ? totalHistoryPages : totalManufacturerPages}
            edgePageCount={2}
            middlePagesSiblingCount={1}
            className="pagination_wrapper"
            truncableText=". . ."
            truncableClassName="truncable"
        >
            <div
                data-testid="previous2"
                className={getPreviousClassName()}
                onClick={() => handlePreviousButton()}>
                {previous2}
            </div>
            <Pagination.PrevButton className="previous_button">{previous}</Pagination.PrevButton>
            <Pagination.PageButton
                activeClassName="activ_page"
                inactiveClassName="inactiv_page"
                className="pages"
            />
            <Pagination.NextButton className="next_button">{next}</Pagination.NextButton>
            <div
                data-testid="next2"
                className={getNextClassName()}
                onClick={() => handleNextButton()}>
                {next2}
            </div>
        </Pagination>
    )
}

export default PaginationComponent
