import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { GeneralTexts } from '../../language/Texts';
import { IBackendResult } from '../../models/IBackendResult';
import { IManufacturerItem } from '../../models/IManufacturerList';
import { Paths } from '../../models/Paths';
import { HttpRequests } from '../../utils/HttpRequests';
import Loader from '../loader/Loader';
import "./DeleteForm.css";

interface IDeleteProps {
    toggleModal: () => void,
    modifyData: IManufacturerItem,
    getManufacturerList: () => Promise<void>
}

function DeleteForm({ toggleModal, modifyData, getManufacturerList }: IDeleteProps) {
    const [alertOpt, setALertOpt] = useState({ isOpen: false, message: '', variant: '' })
    const [isLoaderOpen, setIsLoaderOpen] = useState<boolean>(false)
    const navigate = useNavigate()

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoaderOpen(true)
        try {
            let result = await new HttpRequests().deleteManufacturer(modifyData?.trusted_manufacturer_id)
            setSuccessOptions(result)
        } catch (error) {
            setALertOpt({ isOpen: true, message: 'ERROR', variant: 'danger' })
            console.log('error on deleting manufacturer', error);
        }
        setIsLoaderOpen(false)
        setTimeout(() => {
            setALertOpt({ isOpen: false, message: '', variant: '' })
            toggleModal()
        }, 2000)
    };

    const setSuccessOptions = (result: IBackendResult) => {
        if (result.success) {
            getManufacturerList()
            navigate(Paths.trustedmanufacturers)
            toggleModal()
        } else {
            setALertOpt({ isOpen: true, message: 'ERROR', variant: 'danger' })
            console.log('error on updating data: ', result.error);
        }
    }

    useEffect(() => {

    }, [modifyData])

    return (
        <div className="form_wrapper_delete">
            {isLoaderOpen ? <Loader /> :
                <>
                    <Alert className="delete_alert" variant={alertOpt.variant} show={alertOpt.isOpen} >
                        {alertOpt.message}
                    </Alert>
                    <form data-testid="form" onSubmit={handleSubmit}>
                        <div className="form_group">
                            <Button className="general_button cancel_button" onClick={toggleModal} >
                                {GeneralTexts.cancel}
                            </Button>
                            <Button data-testid="submit_button" type="submit" className="general_button delete_button" >
                                {GeneralTexts.delete}
                            </Button>
                        </div>
                    </form>
                </>
            }
        </div>
    )
}

export default DeleteForm
