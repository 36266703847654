import { useNavigate } from "react-router-dom";
import { FooterTexts } from "../../language/Texts";
import { GeneralDefinitions } from "../../models/GeneralDefinitions";
import { Paths } from "../../models/Paths";
import versionFile from '../../version.json';
import './Footer.css';

function Footer({ backendVersion }) {
    let navigate = useNavigate()

    const getBackendVersion = () => {
        if (sessionStorage.getItem(GeneralDefinitions.backendVersion)) {
            return "BE: " + sessionStorage.getItem(GeneralDefinitions.backendVersion)
        } else if (backendVersion) {
            return "BE: " + backendVersion
        } else {
            return ""
        }
    }

    const getFrontendVersion = () => {
        return "FE: " + versionFile.version
    }

    return (
        <div
            data-testid="footer_wrapper"
            className='footer_wrapper'>
            <div>
                <a
                    className='footer_item'
                    href='https://inevvo-solutions.com/datenschutz-allgemeine-informationen/datenschutz-app/'
                    target="_blank"
                >
                    {FooterTexts.dataSecurity}
                </a>
                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <span
                    onClick={() => navigate(Paths.impressum)}
                    className="footer_item" >
                    {FooterTexts.imprint}
                </span>
                <span className="version">&emsp;{getFrontendVersion()} &nbsp;{getBackendVersion()}</span>
            </div>
        </div>
    )
}

export default Footer
