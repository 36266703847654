import { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ConfirmDialogTexts, GeneralTexts } from '../../language/Texts';
import KeycloakService from '../../utils/KeycloakService';
import './ConfirmDialog.css';

function ConfirmDialog({ isModalOpen, setIsModalOpen }) {
    const [timer, setTimer] = useState(30); // 30 seconds
    const firstStart = useRef(true);
    const tick: any = useRef();

    const extendSession = () => {
        setIsModalOpen(false)
        setTimer(30)
    }

    const endSession = () => {
        KeycloakService.logout()
    }

    if (timer === 0) {
        endSession()
    }
    const dispSecondsAsMins = (seconds) => {
        const seconds_ = seconds % 60;
        return (seconds_.toString());
    };

    useEffect(() => {
        if (firstStart.current) {
            firstStart.current = !firstStart.current;
            return;
        }
        if (isModalOpen) {
            tick.current = setInterval(() => {
                setTimer((timer) => timer - 1);
            }, 1000);
        } else {
            clearInterval(tick.current);
        }

        return () => clearInterval(tick.current);
    }, [isModalOpen]);


    return (
        <>
            <Modal show={isModalOpen} >
                <Modal.Body className='modal_header' >{ConfirmDialogTexts.sessionExpired}</Modal.Body>
                <Modal.Body className='modal_title' > {ConfirmDialogTexts.askToGoon}</Modal.Body>
                <Modal.Footer className='modal_button_groups'>
                    <Button className='modal_button' variant="secondary" onClick={extendSession}>
                        {GeneralTexts.yes}
                    </Button>
                    <Button className='modal_button' variant="primary" onClick={endSession}>
                        {GeneralTexts.no}
                    </Button>
                </Modal.Footer>
                <Modal.Body className='timer'>Auto Logout: {dispSecondsAsMins(timer)}</Modal.Body>
            </Modal>
        </>
    );
}
export default ConfirmDialog