import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'
import { GeneralTexts } from '../../language/Texts'
export interface IUpdateProps {
    disabled: boolean
}

function UpdateButton({ disabled }: IUpdateProps) {
    return (
        <div className='submit_button_wrapper'>
            <Button
                type="submit"
                className="general_button"
                disabled={disabled}>
                <FontAwesomeIcon className="save_icon" icon={faSave} /> {GeneralTexts.update}
            </Button>
        </div>
    )
}

export default UpdateButton
