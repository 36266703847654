import React from 'react'
import "./InputPart.css"
export interface IInputPartProps {
    labelText: string,
    type: string,
    value: string,
    disabled: boolean,
    required: boolean,
    onChange?: (e) => void
}
function InputPart({ labelText, type, value, disabled, required, onChange }: IInputPartProps) {
    return (
        <div className="input_group">
            <label className='label_input'>{labelText}</label>
            <input
                type={type}
                required={required}
                value={value}
                placeholder={labelText === "E-Mail" ? '' : labelText}
                disabled={disabled}
                className='input_item'
                onChange={(e) => onChange ? onChange(e) : null}
            />
        </div>
    )
}
export default InputPart
