import { IManufacturerItem } from '../../models/IManufacturerList'
import { RetrofitManufacturer } from '../../models/RetrofitManufacturer'
import CreateView from './CreateView'
import './RetrofitQrCreate.css'
import Loader from '../../components/loader/Loader';

interface RetrofitCreateProps {
    manufacturerList: IManufacturerItem[] | undefined,
    getManufacturerList: () => void
}

function RetrofitQrCreate({ manufacturerList, getManufacturerList }: RetrofitCreateProps) {
    // In order to create retrofit id, retrofit manufacturer should be ready.
    const retrofitManufacturer = manufacturerList?.filter((item) => {
        return item.trusted_manufacturer_id === RetrofitManufacturer.id
    })[0]

    return (
        <>
            {retrofitManufacturer ?
                <CreateView
                    manufacturerList={manufacturerList}
                    getManufacturerList={getManufacturerList}
                /> :
                <div className='retrofit_loader'>
                    <Loader />
                </div>
            }
        </>
    )
}

export default RetrofitQrCreate
