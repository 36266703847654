export enum Messages {
    SUCCESSFULLY_UPDATED = "SUCCESSFULLY_UPDATED",
    SUCCESSFULLY_DELETED = "SUCCESSFULLY_DELETED",
    SUCCESSFULLY_CREATED = "SUCCESSFULLY_CREATED",
    SUCCESSFULLY_GET_LIST = "SUCCESSFULLY_GET_LIST",
    FALSE_ID = "FALSE_ID",
    UPDATE_ERROR = "UPDATE_ERROR",
    DELETE_ERROR = "DELETE_ERROR",
    CREATE_ERROR = "CREATE_ERROR",
    GET_LIST_ERROR = "GET_LIST_ERROR",
    UNIQUE_VIOLATION = "UNIQUE_VIOLATION",
    DECYRPT_ERROR = "DECYRPT_ERROR",
    EXTRACT_ERROR = "EXTRACT_ERROR",
    DB_CREATE_ERROR = "DB_CREATE_ERROR",
    ALREADY_EXIST_ERROR = "Dieser Trusted Manufacturer wurde bereits angelegt und kann nicht erneut importiert werden."
}