import { faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { Form } from "react-bootstrap"
import DownloadOptions from "../../components/downloadOptions/DownloadOptions"
import { GeneralTexts, IdLinkCreatePage, QrRecreatePage } from "../../language/Texts"
import { IDownloadOptions } from "../../models/IDownloadOptions"
import { IPdfDimensions } from "../../models/IPdfDimensions"
import { Utils } from "../../utils/Utils"
import { InputValueType } from "../../models/InputValueType.type"

interface InputPartProps {
    idLink: string
    onInputValueChange: (type: InputValueType, value: string) => void
    componentId: string,
    downloadOptions: IDownloadOptions,
    setDownloadOptions: (downloadOptions: IDownloadOptions) => void,
    pdfDimensions: IPdfDimensions,
    setPdfDimensions: ({ pdfWidth, pdfHeight }) => void,
    disabled: boolean,
    isExcelFile: boolean,
    handleFileUpload: (event) => void,
    imageSrc: string,
    selectedFile: any,
    resetExcelFile: () => void
}

function QrRegenerationInputPart({
    idLink,
    onInputValueChange,
    componentId,
    setDownloadOptions, downloadOptions,
    setPdfDimensions, pdfDimensions,
    disabled,
    isExcelFile,
    handleFileUpload,
    imageSrc,
    selectedFile,
    resetExcelFile
}: InputPartProps) {

    const handleDownloadOptions = (option: string) => {
        Utils.handleDownloadOptions(option, downloadOptions, setDownloadOptions)
    }

    useEffect(() => {
    }, [imageSrc])

    return (
        <div className="id_input_part">
            <div className="id_label regenerate_input_1">
                <label className='id_label'>{QrRecreatePage.enterIdLink} </label>
                <input
                    value={idLink}
                    placeholder={IdLinkCreatePage.idLink}
                    className='id_number_input  file_input'
                    type="url"
                    required
                    onChange={(e) => onInputValueChange('idLink', e.target.value)}
                    disabled={disabled || isExcelFile || componentId !== ''}
                />

                <label className='seperator id_label'>{GeneralTexts.or}</label>

                <label className='id_label'>{QrRecreatePage.enterComponentId} </label>
                <input
                    value={componentId}
                    placeholder={"ID"}
                    className='id_number_input'
                    type="text"
                    required
                    onChange={(e) => onInputValueChange('componentId', e.target.value)}
                    disabled={disabled || isExcelFile || idLink !== ''}
                />

                <Form.Group controlId="formFileSm" className="mb-3">
                    <Form.Label className='id_label file_label'>{GeneralTexts.or}</Form.Label>
                    <Form.Label className='id_label file_label'>{QrRecreatePage.importExcelFile}</Form.Label>
                    <Form.Control
                        data-testid='file-input'
                        className='id_number_input file_input'
                        disabled={disabled || idLink !== ''}
                        accept=".xlsx"
                        type="file"
                        value={selectedFile}
                        onChange={(event) => handleFileUpload(event)}
                    />
                    {selectedFile &&
                        <FontAwesomeIcon
                            onClick={() => resetExcelFile()}
                            className="remove_file_icon" icon={faTrashCan} />
                    }
                </Form.Group>
            </div>

            <div className="regenerate_input_2">
                {imageSrc &&
                    <div className="preview_wrapper">
                        <p className="preview_text">Vorschau</p>
                        <img className="preview_qr" src={imageSrc} alt="preview" />
                    </div>}
                <DownloadOptions
                    handleDownloadOptions={handleDownloadOptions}
                    downloadOptions={downloadOptions}
                    pdfDimensions={pdfDimensions}
                    setPdfDimensions={setPdfDimensions}
                    disabled={disabled}
                    enableExcelDownload={false} />
            </div>

        </div>
    )
}

export default QrRegenerationInputPart
