import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IdLinkCreatePage } from "../../language/Texts";

interface SortOptions {
    idIcon: IconDefinition;
    dateIcon: IconDefinition;
    isSortId: boolean;
    isSortDate: boolean;
    reversed: boolean;
    type: string;
}

export const idTitelPart = (
    sortOptions: SortOptions,
    sortHistoryId: () => void,
    searchId: string,
    setSearchId: (item: string) => void) => {
    return <div className='table_head_item'>
        <FontAwesomeIcon className="sort_icon" icon={sortOptions.idIcon} onClick={() => sortHistoryId()} />
        <input
            data-testid='filter_id'
            value={searchId}
            type="text"
            placeholder={IdLinkCreatePage.generatedIds}
            onChange={(e) => setSearchId(e.target.value)}
            className="filter_input"
        />
    </div>
}

export const dateTitelPart = (sortOptions: SortOptions, sortHistoryDate: () => void) => {
    return <div className='table_head_item'>
        <FontAwesomeIcon className="sort_icon" icon={sortOptions.dateIcon} onClick={() => sortHistoryDate()} />
        <label htmlFor="">{IdLinkCreatePage.date}</label>
    </div>
}