import PageInfoSection from '../../components/pageInfoSection/PageInfoSection'
import { RetrofitCreatePage, SidebarTexts } from '../../language/Texts'

function RetrofitQrTitlePart() {
    return (
        <PageInfoSection
            pageTitle={SidebarTexts.createRetrofit}
            infoTitle={RetrofitCreatePage.retrofitQr}
            infoText1={RetrofitCreatePage.retrofitInfoText1}
            infoText2={RetrofitCreatePage.retrofitInfoText2}
            logoText={RetrofitCreatePage.retrofitQr}
        />
    )
}

export default RetrofitQrTitlePart
