import React from 'react'
import { IDownloadOptions } from '../../models/IDownloadOptions'
import { IPdfDimensions } from '../../models/IPdfDimensions'
import { GeneralTexts } from '../../language/Texts'
import DownloadOptionsController from '../DownloadOptionsController/DownloadOptionsController'
import './DownloadOptions.css'

interface DownloadOptionsProps {
    handleDownloadOptions: (option: string) => void,
    downloadOptions: IDownloadOptions,
    pdfDimensions?: IPdfDimensions,
    setPdfDimensions?: ({ pdfWidth, pdfHeight }) => void,
    disabled?: boolean,
    enableExcelDownload?: boolean
}

function DownloadOptions({
    handleDownloadOptions,
    downloadOptions,
    pdfDimensions, setPdfDimensions,
    disabled,
    enableExcelDownload = true }: DownloadOptionsProps) {
    return (
        <div className="download_options">
            <p className="options_title">{GeneralTexts.downloadOptions}:</p>
            <DownloadOptionsController
                handleDownloadOptions={handleDownloadOptions}
                downloadOptions={downloadOptions}
                pdfDimensions={pdfDimensions}
                setPdfDimensions={setPdfDimensions}
                disabled={disabled}
                enableExcelDownload={enableExcelDownload}
            />
        </div>
    )
}

export default DownloadOptions
