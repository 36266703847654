import { faCloudUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { AddManufacturerPage } from '../../language/Texts'
import { IFileLoaded } from '../../models/IFileLoaded'
import './DropzoneComp.css'

export default function DropzoneComp({ onFileLoaded }: IFileLoaded) {
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file: File) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                onFileLoaded(file)
            }
            reader.readAsArrayBuffer(file)
        })
    }, [])
    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    return (
        <div {...getRootProps()} className='dropzone_wrapper' >
            <input {...getInputProps()} />
            <FontAwesomeIcon className="upload_icon" icon={faCloudUpload} />
            <p className='drop_text'>{AddManufacturerPage.dragDropText}</p>
        </div>
    )
}