import { GeneralTexts } from '../../language/Texts'
import './ErrorPage.css'

function ErrorPage({ error }) {
    return (
        <div className='error_page'>
            <h2 className='error_item'>{GeneralTexts.sthWrong}</h2>
            <h2 className='error_item'>
                {error.response?.status ? `Status: ${error.response?.status} ` : null}
            </h2>
            <h2 className='error_item'>
                {error.response?.data.message ? `Error: ${error.response?.data.message} ` : null}
            </h2>
        </div>
    )
}

export default ErrorPage
