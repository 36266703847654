import { GeneralTexts } from '../../language/Texts'
import { FileDetail } from '../../models/IHistoryFileList'
import { useGlobalContext } from '../../utils/GlobalContext'
import { Utils } from '../../utils/Utils'

interface FilteredTableProps {
    filteredHistoryElements?: FileDetail[],
    setDataTypeAndDownloadFile: (manufacturerId: string, fileDetail: FileDetail) => void
}
function FilteredTable({ filteredHistoryElements, setDataTypeAndDownloadFile }: FilteredTableProps) {
    const { historyPage } = useGlobalContext()
    return (
        <tbody>
            {
                filteredHistoryElements?.slice(historyPage * 10, historyPage * 10 + 10)
                    .map(function (fileDetail, index2) {
                        return (
                            <tr key={index2} data-testid="table_item">
                                <td className='body_item'>{fileDetail?.firstID + " - " + fileDetail?.lastID}</td>
                                <td className='body_item'>{Utils.arrangeTime(fileDetail?.birthDate)}</td>
                                <td className='body_item'>{fileDetail?.saveOptions?.quantity}</td>
                                <td
                                    data-testid="download_history_button"
                                    onClick={() => setDataTypeAndDownloadFile(fileDetail?.manufacturerId!, fileDetail)}
                                    className='body_item download_file'>
                                    {GeneralTexts.download}
                                    <span className='display_downloaded_dimensions'>
                                        {` (${fileDetail?.saveOptions?.pdf_width}mm*${fileDetail?.saveOptions?.pdf_height}mm)`}
                                    </span>
                                </td>
                            </tr>
                        )
                    })
            }
        </tbody>
    )
}

export default FilteredTable
