import PageInfoSection from '../../components/pageInfoSection/PageInfoSection'
import { QrRecreatePage, SidebarTexts } from '../../language/Texts'

function QrRegenerationTitle() {
    return (
        <PageInfoSection
            pageTitle={SidebarTexts.recreateQrCode}
            infoTitle={QrRecreatePage.regeneratefromQRCodes}
            infoText1={QrRecreatePage.regenerateText}
        />
    )
}

export default QrRegenerationTitle
