import { faSort, faSortDown, faSortUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CustomModal from '../../components/customModal/CustomModal';
import AppHeader from '../../components/header/AppHeader';
import Loader from '../../components/loader/Loader';
import PaginationComponent from '../../components/pagination/PaginationComponent';
import { GeneralTexts, ManufacturerPage } from '../../language/Texts';
import { IManufacturerItem } from '../../models/IManufacturerList';
import { Paths } from '../../models/Paths';
import { RetrofitManufacturer } from '../../models/RetrofitManufacturer';
import { useGlobalContext } from "../../utils/GlobalContext";
import "./ManufacturerTable.css";

function ManufacturerTable({ manufacturerList, getManufacturerList }) {

    const [filteredManufacturerName, setFilteredManufacturerName] = useState("")
    const [filteredTrustedManufacturerId, setFilteredTrustedManufacturerId] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modifyData, setmodifyData] = useState<IManufacturerItem>();
    const [slicedManufacturerList, setSlicedManufacturerList] = useState<IManufacturerItem[]>();
    const [sortOptions, setSortOptions] = useState({
        nameIcon: faSort,
        idIcon: faSort,
        isSortName: false,
        isSortId: false,
        reversed: false,
        type: "trusted_manufacturer_name",
        isSortActiv: false
    });

    let contextVariables
    const { totalHistoryPages,
        setTotalHistoryPages,
        totalManufacturerPages,
        setTotalManufacturerPages,
        setManufacturerPage,
        manufacturerPage,
        historyPage,
        setHistoryPage } = contextVariables = useGlobalContext()

    let navigate = useNavigate()
    const navigatePage = (path: string, manufacturerItem?: IManufacturerItem, isOverview?: boolean) => {
        if (isOverview) {
            navigate(path, { state: manufacturerItem })
        } else {
            navigate(path, { state: { manufacturerItem, isCreate: false, isFromOverview: false } })
        }
    }

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const iconActions = (modifyData: IManufacturerItem) => {
        toggleModal()
        setmodifyData(modifyData)
    }

    let filteredManufacturerList: IManufacturerItem[] | undefined = manufacturerList?.filter(function (listItem) {
        return (
            listItem.trusted_manufacturer_name.toUpperCase().includes(filteredManufacturerName.toUpperCase())
            && listItem.trusted_manufacturer_id.toUpperCase().includes(filteredTrustedManufacturerId.toUpperCase()))
    });

    const sortManufacturerList = (reversed: boolean, type: string): IManufacturerItem[] => {
        if (!reversed) {
            return filteredManufacturerList!.sort((a, b) => (a[type].toUpperCase() > b[type].toUpperCase()) ? 1 : ((b[type].toUpperCase() > a[type].toUpperCase()) ? -1 : 0))
        } else {
            return filteredManufacturerList!.sort((b, a) => (a[type].toUpperCase() > b[type].toUpperCase()) ? 1 : ((b[type].toUpperCase() > a[type].toUpperCase()) ? -1 : 0))
        }
    }

    const adjustSortOptions = (nameIcon: IconDefinition, idIcon: IconDefinition, isSortName: boolean, isSortId: boolean, reversed: boolean, type: string) => {
        setSortOptions((prev) => ({
            ...prev,
            nameIcon,
            idIcon,
            isSortName,
            isSortId,
            reversed,
            type,
            isSortActiv: true
        }))
        filteredManufacturerList = sortManufacturerList(reversed, type)
        setSlicedManufacturerList(filteredManufacturerList.slice(manufacturerPage * 10, manufacturerPage * 10 + 10))
    }

    const sortManufacturerName = () => {
        if (sortOptions.isSortName) {
            adjustSortOptions(faSortUp, faSort, false, false, false, "trusted_manufacturer_name")
        } else {
            adjustSortOptions(faSortDown, faSort, true, false, true, "trusted_manufacturer_name")
        }
    }

    const sortManufacturerId = () => {
        if (sortOptions.isSortId) {
            adjustSortOptions(faSort, faSortUp, false, false, false, "trusted_manufacturer_id")
        } else {
            adjustSortOptions(faSort, faSortDown, false, true, true, "trusted_manufacturer_id")
        }
    }

    const handleEditButtons = (manufacturerItem: IManufacturerItem) => {
        if (manufacturerItem.trusted_manufacturer_id !== RetrofitManufacturer.id) {
            return (
                <td className='body_item action_buttons'>
                    <div
                        onClick={() => navigatePage(Paths.updatemanufacturer, manufacturerItem)}
                        className='table_icon update_icon'>< FaPencilAlt />
                    </div>
                    <div
                        onClick={() => iconActions(manufacturerItem)}
                        className='table_icon delete_icon'>< FaTrash />
                    </div>
                </td>)
        } else {
            return (<td />)
        }
    }

    const manufacturerPart = () => {
        return (
            <div className='table_head_item'>
                <FontAwesomeIcon className="sort_icon" icon={sortOptions.nameIcon} onClick={() => sortManufacturerName()} />
                <input
                    value={filteredManufacturerName}
                    type="text"
                    placeholder={ManufacturerPage.manufacturerName}
                    onChange={(e) => setFilteredManufacturerName(e.target.value)}
                    className="filter_input"
                />
            </div>
        )
    }

    const trustedManufacturerIDPart = () => {
        return (
            <div className='table_head_item'>
                <FontAwesomeIcon className="sort_icon" icon={sortOptions.idIcon} onClick={() => sortManufacturerId()} />
                <input
                    value={filteredTrustedManufacturerId}
                    type="text"
                    placeholder='TM-ID'
                    onChange={(e) => setFilteredTrustedManufacturerId(e.target.value)}
                    className="filter_input"
                />
            </div>
        )
    }

    const loaderPart = () => {
        return (
            <div className="table_loader">
                <Loader />
            </div>
        )
    }

    useEffect(() => {
        if (filteredManufacturerList && filteredManufacturerList.length > -1) {
            setTotalManufacturerPages(Math.ceil(filteredManufacturerList?.length / 10))
            const slicedManList = sortManufacturerList(sortOptions.reversed, sortOptions.type).slice(manufacturerPage * 10, manufacturerPage * 10 + 10)
            setSlicedManufacturerList(slicedManList)
        }
    }, [isModalOpen, filteredManufacturerName, filteredTrustedManufacturerId, manufacturerList, manufacturerPage])

    return (
        <div className='table_wrapper' data-testid="table_wrapper" >
            <AppHeader />
            {!slicedManufacturerList ? loaderPart() :
                <div>
                    <div className="title-add_button">
                        <h3 className='box_title'>{ManufacturerPage.manufacturerList}</h3>
                        <div className="box_button">
                            <Button
                                onClick={() => navigatePage("/addmanufacturer")}
                                variant="primary"
                                className='general_button'
                                data-testid="add_manufacturer"
                            >+ {GeneralTexts.trustedManufacturer} </Button>
                        </div>
                    </div>
                    <div className="table_main">
                        <Table striped hover >
                            <thead>
                                <tr>
                                    <th className='head_item row1'>{manufacturerPart()}</th>
                                    <th className='head_item row2'>{trustedManufacturerIDPart()}</th>
                                    <th className='head_item head_counter row3'>{ManufacturerPage.counter}</th>
                                    <th className='head_item action_title row4'>{ManufacturerPage.actions}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {slicedManufacturerList?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className='body_item'
                                                onClick={() => navigatePage("/overview", item, true)}
                                            >{item.trusted_manufacturer_name}</td>
                                            <td className='body_item'>{item.trusted_manufacturer_id}</td>
                                            <td className='body_item body_counter'>{item.counter}</td>
                                            {handleEditButtons(item)}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {totalManufacturerPages > 1 ?
                            <PaginationComponent
                                contextVariables={contextVariables}
                            />
                            : null}
                    </div>
                    <CustomModal
                        toggleModal={toggleModal}
                        isModalOpen={isModalOpen}
                        modifyData={modifyData!}
                        getManufacturerList={getManufacturerList}
                    />
                </div>
            }
        </div>
    )
}

export default ManufacturerTable
