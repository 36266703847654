import i18n from './i18n';

export class HeaderTexts {
    private constructor() { }
    static get identificationLinkGenerator() { return i18n.t(`identificationLinkGenerator.${i18n.language}`) }
}


export class SidebarTexts {
    private constructor() { }
    static get logout() { return i18n.t(`logout.${i18n.language}`) }
    static get createRetrofit() { return i18n.t(`createRetrofit.${i18n.language}`) }
    static get createIdLink() { return i18n.t(`createIdLink.${i18n.language}`) }
    static get trustedManufacturer() { return i18n.t(`trustedManufacturer.${i18n.language}`) }
    static get recreateQrCode() { return i18n.t(`recreateQrCode.${i18n.language}`) }
    static get minimizeMenu() { return i18n.t(`minimizeMenu.${i18n.language}`) }
    static get apiDocumentation() { return i18n.t(`apiDocumentation.${i18n.language}`) }
}

export class RetrofitCreatePage {
    constructor() { }
    static get retrofitQr() { return i18n.t(`retrofitQr.${i18n.language}`) }
    static get retrofitInfoText1() { return i18n.t(`retrofitInfoText1.${i18n.language}`) }
    static get retrofitInfoText2() { return i18n.t(`retrofitInfoText2.${i18n.language}`) }
    static get retrofitFailViewTitle() { return i18n.t(`retrofitFailViewTitle.${i18n.language}`) }
    static get retrofitFailViewText1() { return i18n.t(`retrofitFailViewText1.${i18n.language}`) }
    static get retrofitFailViewText2() { return i18n.t(`retrofitFailViewText2.${i18n.language}`) }
    static get quantityOftheLinks() { return i18n.t(`quantityOftheLinks.${i18n.language}`) }
}

export class IdLinkCreatePage {
    constructor() { }
    static get generateIdLinkQrCode() { return i18n.t(`generateIdLinkQrCode.${i18n.language}`) }
    static get idLink() { return i18n.t(`idLink.${i18n.language}`) }
    static get idLinkInfoText1() { return i18n.t(`idLinkInfoText1.${i18n.language}`) }
    static get idLinkInfoText2() { return i18n.t(`idLinkInfoText2.${i18n.language}`) }
    static get chooseManufacturer() { return i18n.t(`chooseManufacturer.${i18n.language}`) }
    static get history() { return i18n.t(`history.${i18n.language}`) }
    static get historyTitle() { return i18n.t(`historyTitle.${i18n.language}`) }
    static get date() { return i18n.t(`date.${i18n.language}`) }
    static get generatedIds() { return i18n.t(`generatedIds.${i18n.language}`) }
}


export class ManufacturerPage {
    constructor() { }
    static get manufacturerList() { return i18n.t(`manufacturerList.${i18n.language}`) }
    static get counter() { return i18n.t(`counter.${i18n.language}`) }
    static get actions() { return i18n.t(`actions.${i18n.language}`) }
    static get manufacturerName() { return i18n.t(`manufacturerName.${i18n.language}`) }
}

export class QrRecreatePage {
    constructor() { }
    static get regeneratefromQRCodes() { return i18n.t(`regeneratefromQRCodes.${i18n.language}`) }
    static get regenerateText() { return i18n.t(`regenerateText.${i18n.language}`) }
    static get enterIdLink() { return i18n.t(`enterIdLink.${i18n.language}`) }
    static get enterComponentId() { return i18n.t(`enterComponentId.${i18n.language}`) }
    static get importExcelFile() { return i18n.t(`importExcelFile.${i18n.language}`) }
    static get preview() { return i18n.t(`preview.${i18n.language}`) }
}

export class AddManufacturerPage {
    constructor() { }
    static get addManufacturer() { return i18n.t(`addManufacturer.${i18n.language}`) }
    static get chooseFile() { return i18n.t(`chooseFile.${i18n.language}`) }
    static get noFileSelected() { return i18n.t(`noFileSelected.${i18n.language}`) }
    static get dragDropText() { return i18n.t(`dragDropText.${i18n.language}`) }
}

export class UpdateManufacturerPage {
    constructor() { }
    static get updateManufacturer() { return i18n.t(`editManufacturer.${i18n.language}`) }
    static get importManufacturer() { return i18n.t(`importManufacturer.${i18n.language}`) }
    static get manufacturerId() { return i18n.t(`manufacturerId.${i18n.language}`) }
    static get baseUrl() { return i18n.t(`baseUrl.${i18n.language}`) }
    static get assignedUser() { return i18n.t(`assignedUser.${i18n.language}`) }
    static get addUser() { return i18n.t(`addUser.${i18n.language}`) }
    static get requiredEmail() { return i18n.t(`requiredEmail.${i18n.language}`) }
    static get userAlreadyExists() { return i18n.t(`userAlreadyExists.${i18n.language}`) }
    static get editManufacturer() { return i18n.t(`editManufacturer.${i18n.language}`) }
    static get nothingChoosen() { return i18n.t(`nothingChoosen.${i18n.language}`) }
    static get chooseFileButton() { return i18n.t(`chooseFileButton.${i18n.language}`) }
    static get image() { return i18n.t(`image.${i18n.language}`) }
}

export class ConfirmDialogTexts {
    constructor() { }
    static get sessionExpired() { return i18n.t(`sessionExpired.${i18n.language}`) }
    static get askToGoon() { return i18n.t(`askToGoon.${i18n.language}`) }
    static get autoLogout() { return i18n.t(`autoLogout.${i18n.language}`) }
}

export class FooterTexts {
    constructor() { }
    static get dataSecurity() { return i18n.t(`dataSecurity.${i18n.language}`) }
    static get imprint() { return i18n.t(`imprint.${i18n.language}`) }
    static get editorTitle() { return i18n.t(`editorTitle.${i18n.language}`) }
    static get disclaimerTitle() { return i18n.t(`disclaimerTitle.${i18n.language}`) }
    static get copyRightTitle() { return i18n.t(`copyRightTitle.${i18n.language}`) }
    static get editorText7() { return i18n.t(`editorText7.${i18n.language}`) }
    static get editorText8() { return i18n.t(`editorText8.${i18n.language}`) }
    static get editorText9() { return i18n.t(`editorText9.${i18n.language}`) }
    static get editorText10() { return i18n.t(`editorText10.${i18n.language}`) }
    static get editorText11() { return i18n.t(`editorText11.${i18n.language}`) }
    static get disclaimerText() { return i18n.t(`disclaimerText.${i18n.language}`) }
    static get copyrightText() { return i18n.t(`copyrightText.${i18n.language}`) }
}

export class GeneralTexts {
    constructor() { }
    static get pdfFormat() { return i18n.t(`pdfFormat.${i18n.language}`) }
    static get generate() { return i18n.t(`generate.${i18n.language}`) }
    static get quantity() { return i18n.t(`quantity.${i18n.language}`) }
    static get text() { return i18n.t(`text.${i18n.language}`) }
    static get textOptional() { return i18n.t(`textOptional.${i18n.language}`) }
    static get downloadOptions() { return i18n.t(`downloadOptions.${i18n.language}`) }
    static get excelFile() { return i18n.t(`excelFile.${i18n.language}`) }
    static get pdfFile() { return i18n.t(`pdfFile.${i18n.language}`) }
    static get pngFile() { return i18n.t(`pngFile.${i18n.language}`) }
    static get trustedManufacturer() { return i18n.t(`trustedManufacturer.${i18n.language}`) }
    static get or() { return i18n.t(`or.${i18n.language}`) }
    static get cancel() { return i18n.t(`cancel.${i18n.language}`) }
    static get import() { return i18n.t(`import.${i18n.language}`) }
    static get update() { return i18n.t(`update.${i18n.language}`) }
    static get delete() { return i18n.t(`delete.${i18n.language}`) }
    static get deleteText() { return i18n.t(`deleteText.${i18n.language}`) }
    static get yes() { return i18n.t(`yes.${i18n.language}`) }
    static get no() { return i18n.t(`no.${i18n.language}`) }
    static get sthWrong() { return i18n.t(`sthWrong.${i18n.language}`) }
    static get download() { return i18n.t(`download.${i18n.language}`) }
    static get successfullyDowndloaded() { return i18n.t(`successfullyDowndloaded.${i18n.language}`) }
    static get failedToDownload() { return i18n.t(`failedToDownload.${i18n.language}`) }
    static get edit() { return i18n.t(`edit.${i18n.language}`) }
    static get idNotExistInDB() { return i18n.t(`idNotExistInDB.${i18n.language}`) }
    static get failedToDeleteManufacturer() { return i18n.t(`failedToDeleteManufacturer.${i18n.language}`) }
    static get failed() { return i18n.t(`failed.${i18n.language}`) }
    static get square() { return i18n.t(`square.${i18n.language}`) }
    static get rectangular() { return i18n.t(`rectangular.${i18n.language}`) }
    static get QRCode() { return i18n.t(`QRCode.${i18n.language}`) }
    static get dataMatrixCode() { return i18n.t(`dataMatrixCode.${i18n.language}`) }
}

export class AlertMessages {
    constructor() { }
    static get successfullyUpdated() { return i18n.t(`successfullyUpdated.${i18n.language}`) }
    static get successfullyDeleted() { return i18n.t(`successfullyDeleted.${i18n.language}`) }
    static get successfullyCreated() { return i18n.t(`successfullyCreated.${i18n.language}`) }
    static get successfullyGetList() { return i18n.t(`successfullyGetList.${i18n.language}`) }
    static get falseId() { return i18n.t(`falseId.${i18n.language}`) }
    static get updateError() { return i18n.t(`updateError.${i18n.language}`) }
    static get deleteError() { return i18n.t(`deleteError.${i18n.language}`) }
    static get createError() { return i18n.t(`createError.${i18n.language}`) }
    static get getListError() { return i18n.t(`getListError.${i18n.language}`) }
    static get uniqueViolation() { return i18n.t(`uniqueViolation.${i18n.language}`) }
    static get decyrptError() { return i18n.t(`decyrptError.${i18n.language}`) }
    static get extractError() { return i18n.t(`extractError.${i18n.language}`) }
    static get dbCreateError() { return i18n.t(`dbCreateError.${i18n.language}`) }
    static get alreadyExistError() { return i18n.t(`alreadyExistError.${i18n.language}`) }
}


