import { useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { GeneralDefinitions } from '../../models/GeneralDefinitions';
import { IBackendResult } from '../../models/IBackendResult';
import { FileDetail, IHistoryFileList } from '../../models/IHistoryFileList';
import { IManufacturerItem } from '../../models/IManufacturerList';
import Create from '../../pages/createTrustedManufacturer/Create';
import IdLinkCreate from '../../pages/idLinkCreate/IdLinkCreate';
import ManufacturerTable from '../../pages/manufacturerTable/ManufacturerTable';
import Overview from '../../pages/overView/Overview';
import RetrofitQrCreate from '../../pages/retrofitQrCreate/RetrofitQrCreate';
import UpdateManufacturer from '../../pages/updateManufacturer/UpdateManufacturer';
import { HttpRequests } from '../../utils/HttpRequests';
import { Utils } from '../../utils/Utils';
import Footer from '../footer/Footer';
import Impressum from '../impressum/Imprint';
import Sidebar from '../sidebar/Sidebar';
import './AppRouter.css';
import { Paths } from '../../models/Paths';
import { RetrofitManufacturer } from '../../models/RetrofitManufacturer';
import ErrorPage from '../../pages/failDisplay/ErrorPage';
import QrRegeneration from '../../pages/qrRegenaration/QrRegeneration';

function AppRouter() {
    const [manufacturerList, setManufacturerList] = useState<IManufacturerItem[] | undefined>(undefined)
    const [getManufacturerError, setGetManufacturerError] = useState<any>();
    const [historyFileList, setHistoryFileList] = useState<FileDetail[]>()
    const [backendVersion, setBackendVersion] = useState<string>()
    const httpRequests = new HttpRequests()
    const token = sessionStorage.getItem('token')
    const currentUser = Utils.tokenDecoder(token!)
    const userInfos = Utils.getUserInfos(token!)
    const isAdmin = userInfos?.roles?.includes("ilg-admin")

    useEffect(() => {
        if (!manufacturerList && !getManufacturerError) {
            getManufacturerList()
        }
        if (filteredManufacturerList && filteredManufacturerList.length > 0) {
            getFilteredIDList()
        }
    }, [manufacturerList])

    const getManufacturerList = async () => {
        try {
            let result = await new HttpRequests().getManufacturerList()
            setBackendVersion(result.headers.backend_version)
            sessionStorage.setItem(GeneralDefinitions.backendVersion, result.headers.backend_version)
            setSuccessOptions(result.data)
        } catch (error) {
            setGetManufacturerError(error)
            console.log('error on getting trusted manufacturer list: ', error);
        }
        return
    }

    const setSuccessOptions = (result: IBackendResult) => {
        if (result.success) {
            setManufacturerList(result.data)
        } else {
            setGetManufacturerError(result.error)
            console.log('error on getting trusted manufacturer list: ', result.message, result.error);
        }
    }

    // Filter manufacturer according to current user (user rights)
    let filteredManufacturerList = manufacturerList?.filter(manufacturerItem => {
        return manufacturerItem?.user?.some((userItem) => {
            return userItem.username.toLowerCase() === currentUser.toLowerCase()
        });
    })
    // if user is not admin, tm is filtered on backend.
    if (!isAdmin) {
        filteredManufacturerList = manufacturerList?.filter(item => item.trusted_manufacturer_id !== RetrofitManufacturer.id)
    }

    const getFilteredIDList = () => {
        const idList: string[] = []
        for (let item of filteredManufacturerList!) {
            idList.push(item.trusted_manufacturer_id)
        }
        getHistory(idList)
    }

    const arrangeHistoryFileList = (historyFileList: IHistoryFileList) => {
        const historyElements: FileDetail[] = []
        Object.keys(historyFileList).forEach(item => {
            historyFileList[item].forEach(subItem => {
                subItem["manufacturerId"] = item
                historyElements.push(subItem)
            })
        })
        setHistoryFileList(historyElements)
    }

    const getHistory = async (idList: string[]) => {
        try {
            let result = await httpRequests.getHistory(idList)
            if (result.success) {
                arrangeHistoryFileList(result.data)
            } else {
                console.log('error on getting history:', result.error);
            }
        }
        catch (error) {
            console.log('error on getting history:', error);
        }
    }

    const isSwaggerPath = window.location.pathname === '/swagger';

    return (
        <div className='app_router'>
            <Router>
                {!getManufacturerError ? <Sidebar filteredManufacturerList={filteredManufacturerList}>
                    <div className='main_content'>
                        <div className="routes_wrapper">
                            <Routes>

                                <Route path="/" element={<Navigate to={Paths.retrofitcreate} replace={true} />} />

                                <Route path={Paths.trustedmanufacturers} element={
                                    isAdmin ? <ManufacturerTable manufacturerList={manufacturerList} getManufacturerList={getManufacturerList} />
                                        : <Navigate to={Paths.retrofitcreate} replace />
                                } />

                                <Route path={Paths.addmanufacturer} element={
                                    isAdmin ? <Create />
                                        : <Navigate to={Paths.retrofitcreate} replace />
                                } />

                                <Route path={Paths.impressum} element={<Impressum />} />

                                <Route path={Paths.idlinkcreate}
                                    element={
                                        <IdLinkCreate
                                            getManufacturerList={getManufacturerList}
                                            filteredManufacturerList={filteredManufacturerList}
                                            historyFileList={historyFileList!}
                                        />}
                                />

                                <Route path={Paths.retrofitcreate}
                                    element={
                                        <RetrofitQrCreate
                                            manufacturerList={manufacturerList}
                                            getManufacturerList={getManufacturerList}
                                        />}
                                />
                                <Route path={Paths.regenerate}
                                    element={
                                        <QrRegeneration />}
                                />

                                <Route
                                    path={Paths.updatemanufacturer}
                                    element={
                                        isAdmin ? <UpdateManufacturer
                                            getManufacturerList={getManufacturerList}
                                        />
                                            : <Navigate to={Paths.retrofitcreate} replace />
                                    } />

                                <Route
                                    path={Paths.importmanufacturer}
                                    element={
                                        isAdmin ? <UpdateManufacturer
                                            getManufacturerList={getManufacturerList}
                                        />
                                            : <Navigate to={Paths.retrofitcreate} replace />
                                    } />

                                <Route
                                    path={Paths.overview}
                                    element={
                                        isAdmin ? <Overview
                                            getManufacturerList={getManufacturerList}
                                        />
                                            : <Navigate to={Paths.retrofitcreate} replace />
                                    } />

                                {!isSwaggerPath && (
                                    <Route
                                        path="*"
                                        element={<Navigate to={Paths.retrofitcreate} replace={true} />}
                                    />
                                )}

                            </Routes>
                        </div>
                        <Footer backendVersion={backendVersion} />
                    </div>
                </Sidebar> : <ErrorPage error={getManufacturerError} />}
            </Router >
        </div >
    )
}

export default AppRouter
