import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import translation from "../../language/translation.json";
import './Sidebar.css';

function LanguageSelector({ isOpen }) {

    const sidebar_item_style = (show: boolean) => {
        return { justifyContent: show ? "unset" : "center", display: show ? "" : "none" }
    }
    const languages = translation.translation['language'];
    const languageCodes = Object.keys(languages)
    const languageNames = Object.values(languages)

    const { i18n } = useTranslation();
    const selectLanguage = (language: string) => {
        return languages[language]
    }

    const handleChangeLanguage = (eventKey) => {
        i18n.changeLanguage(eventKey);
    };

    return (
        <Dropdown onSelect={handleChangeLanguage}>
            <Dropdown.Toggle className='language_dropdown'>
                <div className="sidebar_footer">
                    <div className="sidebar_icon">
                        <FontAwesomeIcon className="sidebar_icon" icon={faGlobe} /></div>
                    <div style={sidebar_item_style(isOpen)} className="sidebar_text sidebar_language_selector">{selectLanguage(i18n.language)}</div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className='dropdown_menu'>
                {languageCodes.map((languageCode, index) => {
                    return <Dropdown.Item className='dropdown_item' eventKey={languageCode} key={index}>{languageNames[index]}</Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default LanguageSelector