import { GeneralTexts } from '../../language/Texts'
import { IDownloadOptions } from '../../models/IDownloadOptions'
import { IPdfDimensions } from '../../models/IPdfDimensions'
import SwitchButton from '../switchButton/SwitchButton'

interface PdfAndCodeOptionsControllerProps {
    handleDownloadOptions: (option: string) => void,
    downloadOptions: IDownloadOptions,
    pdfDimensions: IPdfDimensions,
    setPdfDimensions: ({ pdfWidth, pdfHeight }) => void,
    disabled?: boolean,
}

function PdfAndCodeOptionsController({
    handleDownloadOptions,
    downloadOptions,
    pdfDimensions,
    setPdfDimensions,
    disabled
}: PdfAndCodeOptionsControllerProps) {
    return (
        <>
            <span>{GeneralTexts.pdfFormat}: </span>
            <input
                data-testid="pdf_width"
                className='pdf_size'
                type={"number"}
                inputMode="numeric"
                value={pdfDimensions.pdfWidth}
                onChange={(e) => setPdfDimensions({ ...pdfDimensions, pdfWidth: e.target.value })}
                disabled={disabled}
            />
            <span> mm x </span>
            <input
                data-testid="pdf_height"
                className='pdf_size'
                type={"number"}
                inputMode="numeric"
                value={pdfDimensions.pdfHeight}
                onChange={(e) => setPdfDimensions({ ...pdfDimensions, pdfHeight: e.target.value })}
                disabled={disabled}
            />
            <span> mm</span>
            <SwitchButton
                handleDownloadOptions={handleDownloadOptions}
                downloadOptions={downloadOptions}
            />
        </>
    )
}

export default PdfAndCodeOptionsController
