import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation from "./translation.json";
import { Utils } from "../utils/Utils";

const resources = {
    DE: {
        translation: translation.translation
    },
    EN: {
        translation: translation.translation
    },
};
const lang = Utils.getBrowserLanguage().toUpperCase();
i18n
    .use(initReactI18next)
    .init({
        debug: false,
        lng: lang,
        fallbackLng: 'EN', // Fallback language
        resources,
    });

if (!Utils.isLanguageSupported(lang)) {
    i18n.changeLanguage('EN');
}

export default i18n;
