import React from 'react'
import './OverviewImage.css'

interface OverviewImageProps {
    labelText: string,
    image: string,
    imagePath: string
}

function OverviewImage({ labelText, image, imagePath }: OverviewImageProps) {
    return (
        <div className="input_group">
            <label className='label_input'>{labelText}</label>
            <div className='overview_image_wrapper'>
                <span className='input_item'>{image}</span>
                <img className='overview_image' src={decodeURIComponent(imagePath)} />
            </div>
        </div>
    )
}

export default OverviewImage
