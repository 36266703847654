import { faBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SidebarTexts } from '../../language/Texts';
import './Sidebar.css';

function LinkToSwagger({ isOpen }) {
    const sidebar_item_style = (show: boolean) => {
        return { justifyContent: show ? "unset" : "center", display: show ? "" : "none" }
    }

    const handleClick = (event) => {
        window.open(window.location.origin + "/swagger", "_blank");
    }

    return (
        <div
            data-testid="link_to_swagger"
            className="sidebar_footer"
            onMouseDown={handleClick}
        >
            <FontAwesomeIcon className="sidebar_icon" icon={faBook} />
            <p style={sidebar_item_style(isOpen)} className="sidebar_text" >{SidebarTexts.apiDocumentation}</p>
        </div>
    )
}

export default LinkToSwagger