import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import DropzoneComp from '../../components/dropzone/DropzoneComp';
import AppHeader from '../../components/header/AppHeader';
import Loader from '../../components/loader/Loader';
import { AddManufacturerPage, GeneralTexts } from '../../language/Texts';
import { IBackendResult } from '../../models/IBackendResult';
import { IManufacturerItem } from '../../models/IManufacturerList';
import { Messages } from '../../models/Messages';
import { Paths } from '../../models/Paths';
import { HttpRequests } from '../../utils/HttpRequests';
import { Utils } from '../../utils/Utils';
import './Create.css';

function Create() {
  let navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [error, setError] = useState('')
  const [file, setFile] = useState<File>()

  const [disabled, setDisabled] = useState<boolean>(true)

  const onFileLoaded = (file: File) => {
    setFile(file)
    setDisabled(false)
  }

  const onFailure = (errorMessage: string) => {
    setShowLoader(false)
    setShowAlert(true)
    setError(errorMessage)
    setTimeout(() => {
      setShowAlert(false)
    }, 5000)
  }

  const navigatePage = (path: string, manufacturerItem?: IManufacturerItem) => {
    navigate(path, { state: { manufacturerItem, isCreate: true, isFromOverview: false } })
  }

  const adjustErrorMessage = (result: IBackendResult): string => {
    if (result.error === Messages.UNIQUE_VIOLATION) {
      return Utils.getMessageText(result.error)
    } else {
      return `${Utils.getMessageText(result.message)}.  ${result.error ? Utils.getMessageText(result.error) : null}`
    }
  }

  const upload = async () => {
    setShowLoader(true)
    try {
      let result = await new HttpRequests().uploadFile(file!)
      if (result.success) {
        setShowLoader(false)
        navigatePage(Paths.importmanufacturer, result.data)
      } else {
        const errorText = adjustErrorMessage(result)
        onFailure(errorText)
      }
    } catch (error: any) {
      const errorText = error.message ? Utils.getMessageText(error.message) : 'Unexpected Error'
      onFailure(errorText)
    }
  }

  return (
    <div className='file_wrapper'>
      <AppHeader />

      <Alert className="alert_error" variant="warning" show={showAlert} >
        {error}
      </Alert>
      <div style={{ display: showLoader ? "block" : "none", textAlign: "center" }}>
        <Loader />
      </div>

      <div className="upload_title-cancel_button">
        <h3 className='box_title'>{AddManufacturerPage.addManufacturer}</h3>
        <div className="box_button">
          <Button
            onClick={() => navigatePage(Paths.trustedmanufacturers)}
            variant="primary"
            className="general_button cancel_button">{GeneralTexts.cancel}
          </Button>
        </div>
      </div>

      <div className="upload_wrapper">

        <p className="box_text_title">{AddManufacturerPage.chooseFile}</p>
        <DropzoneComp onFileLoaded={onFileLoaded} />

        <div className="uploaded_file-button">
          <p className={file?.name ? 'selected_file' : 'not_selected_file'}>
            {file?.name ? file.name : AddManufacturerPage.noFileSelected}
          </p>
          <Button
            onClick={() => upload()}
            variant="primary"
            className='general_button import_button'
            disabled={disabled}>
            <FontAwesomeIcon className="import_icon" icon={faFileUpload} />
            &nbsp;{GeneralTexts.import}
          </Button>
        </div>

      </div>
    </div>
  )
}

export default Create