export enum Paths {
    trustedmanufacturers = "/trustedmanufacturers",
    overview = "/overview",
    importmanufacturer = "/importmanufacturer",
    idlinkcreate = "/idlinkcreate",
    updatemanufacturer = "/updatemanufacturer",
    retrofitcreate = "/retrofitcreate",
    impressum = "/impressum",
    addmanufacturer = "/addmanufacturer",
    regenerate = "/regenerate"
}