import { AxiosResponse } from "axios";

export const downloadFileOnBrowser = (serverResponse: AxiosResponse<File, File>, dataType: string) => {
    const url = window.URL.createObjectURL(serverResponse.data);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = serverResponse.headers.filename + dataType;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}