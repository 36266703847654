import Keycloak from "keycloak-js";
import { Utils } from "./Utils";
let keycloak = new Keycloak({
    clientId: Utils.isDevelopment() ? 'react-web-app' : 'ilg-frontend',
    realm: Utils.isDevelopment() ? 'dev-test' : 'customer',
    url: 'https://auth.inevvo.de/'
})

const initKeycloak = (onAuthenticated: () => void) => {

    keycloak.init({
        onLoad: 'login-required',
        checkLoginIframe: false
    }).then(authenticated => {
        if (!authenticated)
            keycloak.login()
        else {
            sessionStorage.setItem("token", keycloak.token!)
            refreshToken()
            onAuthenticated()
        }
    }).catch(error => console.log("error on keycloak init", error))
}

const logout = () => {
    const logoutOptions = {
        redirectUri: process.env.REACT_APP_REDIRECTURI,
        id_token_hint: sessionStorage.getItem('token')
    };

    keycloak.logout(logoutOptions).then((success: any) => {
        console.log("--> log: logout success ", success);
    }).catch((error: any) => {
        console.log("--> log: logout error ", error);
    });
}

const updateToken = async () => {
    // updateToken(**seconds**) If the token expires within minValidity seconds, the token is refreshed. 
    // If the session status iframe is enabled, the session status is also checked.
    keycloak.updateToken(60).then(function (refreshed) {
        if (refreshed) {
            refreshToken()
            sessionStorage.setItem('token', keycloak.token as string)
        } else {
            console.log("token could not be updated!");
        }
    }).catch(function () {
        console.log('Failed to refresh the token, or the session has expired');
    })
}

const refreshToken = () => {
    const exp_time = keycloak.tokenParsed?.exp! * 1000
    const now = new Date().getTime()
    // 30 seconds ago from expiration time, token is refreshed
    const refreshTime = 30000
    const timeToRefreshToken = exp_time - now - refreshTime

    setTimeout(() => {
        updateToken()
    }, timeToRefreshToken)
}

const KeycloakService = {
    initKeycloak,
    logout,
}

export default KeycloakService