import { Form } from 'react-bootstrap';
import { GeneralTexts } from '../../language/Texts';
import { IDownloadOptions } from '../../models/IDownloadOptions';
import { IPdfDimensions } from '../../models/IPdfDimensions';
import PdfAndCodeOptionsController from './PdfAndCodeOptionsController';

interface DownloadOptionsControllerProps {
    handleDownloadOptions: (option: string) => void,
    downloadOptions: IDownloadOptions,
    pdfDimensions?: IPdfDimensions,
    setPdfDimensions?: ({ pdfWidth, pdfHeight }) => void,
    disabled?: boolean,
    enableExcelDownload?: boolean
}

function DownloadOptionsController({
    handleDownloadOptions,
    downloadOptions,
    pdfDimensions, setPdfDimensions,
    disabled,
    enableExcelDownload = true }: DownloadOptionsControllerProps) {
    // id is used to select the box by clicking on the label. 
    // Without id , user has to click on the box

    return (
        <div>
            {enableExcelDownload && <Form.Check
                data-testid="excel_check"
                className='checkbox1'
                type={"checkbox"}
                label={GeneralTexts.excelFile}
                checked={downloadOptions.excel}
                onChange={() => handleDownloadOptions('excel')}
                disabled={disabled ?? false}
                id={'checkbox_excel'}
            />}
            <Form.Check
                data-testid="png_check"
                className='checkbox1'
                type={"checkbox"}
                label={GeneralTexts.pngFile}
                checked={downloadOptions.png}
                onChange={() => handleDownloadOptions('png')}
                disabled={disabled ?? false}
                id={'checkbox_png'}
            />
            <Form.Check
                data-testid="pdf_check"
                className='checkbox1'
                type={"checkbox"}
                label={GeneralTexts.pdfFile}
                checked={downloadOptions.pdf}
                onChange={() => handleDownloadOptions('pdf')}
                disabled={disabled ?? false}
                id={'checkbox_pdf'}
            />
            {pdfDimensions && setPdfDimensions &&
                <PdfAndCodeOptionsController
                    handleDownloadOptions={handleDownloadOptions}
                    downloadOptions={downloadOptions}
                    pdfDimensions={pdfDimensions}
                    setPdfDimensions={setPdfDimensions}
                    disabled={disabled}
                />
            }
        </div>
    )
}

export default DownloadOptionsController
