import { GeneralTexts } from '../../language/Texts'

interface CustomTextInputProps {
    customText: string
    handleCustomTextInput: (value: string) => void,
    disabled: boolean
}

function CustomTextInput({ customText, handleCustomTextInput, disabled }: CustomTextInputProps) {
    return (
        <div className="id_label">
            <label className='id_label'>{GeneralTexts.textOptional}</label>
            <input
                value={customText}
                data-testid="customText"
                placeholder={GeneralTexts.text}
                className='id_number_input'
                onChange={(e) => handleCustomTextInput(e.target.value.substring(0, 512))}
                disabled={disabled}
            />
        </div>
    )
}

export default CustomTextInput
