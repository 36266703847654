import { useEffect, useRef } from 'react';
import { IDownloadOptions } from '../../models/IDownloadOptions';

interface SwitchInputProps {
    firstSwitchText: string
    secondSwitchText: string
    isChecked: boolean,
    handleDownloadOptions: (option: string) => void,
    downloadOptions: IDownloadOptions,
    handleDownloadOptionParameter: string
}

function SwitchInputPart({
    firstSwitchText,
    secondSwitchText,
    isChecked,
    handleDownloadOptions,
    downloadOptions,
    handleDownloadOptionParameter,
}: SwitchInputProps) {

    // In order to set two switch button under each other, we calculate the content of them
    const switchTextRef = useRef<HTMLSpanElement>(null);
    useEffect(() => {
        if (switchTextRef.current) {
            const switchTextWidth = switchTextRef.current.offsetWidth;
            const spans = document.querySelectorAll('.switch_text');
            spans.forEach(span => {
                (span as HTMLElement).style.minWidth = `${switchTextWidth}px`;
            });
        }
    }, [downloadOptions]);

    return (
        <div>
            <span
                ref={switchTextRef}
                className='switch_text'>
                {firstSwitchText}
            </span>
            <input
                data-testid={handleDownloadOptionParameter}
                checked={isChecked}
                onChange={() => handleDownloadOptions(handleDownloadOptionParameter)}
                className="toggle"
                type="checkbox" />
            <span>{secondSwitchText}</span>
        </div>
    )
}

export default SwitchInputPart
