import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import AppRouter from './components/appRouter/AppRouter';
import ConfirmDialog from './components/confirmDialog/ConfirmDialog';
import { IdleTimerEvents } from "./models/IdleTimerEvent";
import { GlobalContext } from './utils/GlobalContext';
import { useTranslation } from "react-i18next";
import './App.css';

function App() {
  const [totalManufacturerPages, setTotalManufacturerPages] = useState(0)
  const [manufacturerPage, setManufacturerPage] = useState(0)
  const [totalHistoryPages, setTotalHistoryPages] = useState(0)
  const [historyPage, setHistoryPage] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  useTranslation();

  /******* TIMER *******/
  // if the user is not active, popup is displayed
  const onIdle = () => {
    setIsModalOpen(true)
  }
  // In order to set the time , that user can be inactive
  // events can be configured from IdleTimerEvents
  const { } = useIdleTimer({
    onIdle,
    timeout: 30 * 60 * 1000,
    events: IdleTimerEvents,
    debounce: 500,
  })
  /******* TIMER *******/

  return (
    <GlobalContext.Provider
      value={{
        totalManufacturerPages,
        setTotalManufacturerPages,
        manufacturerPage,
        setManufacturerPage,
        totalHistoryPages,
        setTotalHistoryPages,
        historyPage,
        setHistoryPage,
      }}>
      <div className='app'>
        <AppRouter />
        <ConfirmDialog
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen} />
      </div>
    </GlobalContext.Provider>
  )
}

export default App