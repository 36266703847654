import React from 'react'
import './TextBox.css'

interface TextBoxProps {
    title: string,
    text: string[]
}
function TextBox({ title, text }: TextBoxProps) {
    return (
        <div className="box_wrapper">
            <h4 className="box_text_title">
                {title}
            </h4>
            {text.map(item => {
                return (
                    <span className="id_text">
                        {item}<br />
                    </span>)
            })}

        </div>
    )
}

export default TextBox
