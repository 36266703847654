import PageInfoSection from '../../components/pageInfoSection/PageInfoSection'
import { IdLinkCreatePage } from '../../language/Texts'

function IdLinkTitlePart() {
    return (
        <PageInfoSection
            pageTitle={IdLinkCreatePage.generateIdLinkQrCode}
            infoTitle={IdLinkCreatePage.idLink}
            infoText1={IdLinkCreatePage.idLinkInfoText1}
            infoText2={IdLinkCreatePage.idLinkInfoText2}
            logoText={IdLinkCreatePage.idLink}
        />
    )
}

export default IdLinkTitlePart
