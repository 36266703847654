import { useState } from 'react';
import { Alert, Button, Table } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { v4 as uuid } from 'uuid';
import InputPart from '../../components/inputPart/InputPart';
import { ManufacturerPage, UpdateManufacturerPage } from '../../language/Texts';
import { IUserRight } from '../../models/IUserRight';
import './UpdateManufacturer.css';

interface IAddUserProps {
    userList: IUserRight[]
    setUserList: (userList: IUserRight[]) => void,
    trusted_manufacturer_id: string
}
function AddUser({ setUserList, userList, trusted_manufacturer_id }: IAddUserProps) {
    const [alertOpt, setAlertOpt] = useState({ isOpen: false, message: '', variant: '' })
    const [email, setEmail] = useState<string>('')
    const addUser = () => {
        if (email === "") {
            showWarning(UpdateManufacturerPage.requiredEmail)
            return
        }
        setEmail('')
        let isUserAlreadyExist = userList.some(element => element.username.toLowerCase() === email.toLowerCase());

        if (isUserAlreadyExist) {
            showWarning(UpdateManufacturerPage.userAlreadyExists)
        } else {
            const unique_id = uuid()
            let newUser = { id: unique_id, trusted_manufacturer_id, username: email }
            setUserList([...userList!, newUser])
        }
    }

    const showWarning = (message: string) => {
        setAlertOpt({ isOpen: true, message: message, variant: 'danger' })
        setTimeout(() => {
            setAlertOpt({ isOpen: false, message: "", variant: "" })
        }, 3000)
    }

    const deleteUser = (id) => {
        setUserList(userList.filter(data => data.id != id))
    }

    const onMailChange = (e) => {
        setEmail(e.target.value)
    }

    return (
        <div className="add_user_wrapper">
            <Alert className="alert_error" variant={alertOpt.variant} show={alertOpt.isOpen} >
                {alertOpt.message}
            </Alert>

            <h4 className="box_text_title">
                {UpdateManufacturerPage.assignedUser}
            </h4>
            <div className="add_email">
                <InputPart labelText='E-Mail' type="email" value={email} disabled={false} required={false} onChange={onMailChange} />
                <div className="test">
                    <Button onClick={() => addUser()} className="general_button">
                        {UpdateManufacturerPage.addUser.toUpperCase()}
                    </Button>
                </div>
            </div>
            {userList?.length > 0 ?
                <div className="email_table">
                    <Table striped borderless hover >
                        <thead>
                            <tr>
                                <th className='head_item'>E-Mail</th>
                                <th className='head_item action_title'>{ManufacturerPage.actions}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userList?.map((item, index) => {
                                return (
                                    <tr className="email_table_body" key={index}>
                                        <td className='body_item'>{item.username}</td>
                                        <td className='body_item action_buttons'>
                                            <div
                                                onClick={() => deleteUser(item.id)}
                                                className='table_icon email_delete_icon'>< FaTrash />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                : null}
        </div>
    )
}

export default AddUser
