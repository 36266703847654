import { AxiosResponse } from 'axios'
import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import XLSX from 'xlsx'
import { defaultDownloadOptions } from '../../components/DownloadOptionsController/DefaultDownloadOptions'
import AppHeader from '../../components/header/AppHeader'
import Loader from '../../components/loader/Loader'
import { GeneralTexts } from '../../language/Texts'
import { ContentTypes } from '../../models/ContentTypes'
import { IDownloadOptions } from '../../models/IDownloadOptions'
import { IQrRegenerate } from '../../models/IQrRegenerate'
import { downloadFileOnBrowser } from '../../utils/DownloadFile'
import { HttpRequests } from '../../utils/HttpRequests'
import { setFailOptions } from '../../utils/SetSuccessAndFailOptions'
import { Utils } from '../../utils/Utils'
import './QrRegeneration.css'
import QrRegenerationInputPart from './QrRegenerationInputPart'
import QrRegenerationTitle from './QrRegenerationTitle'
import { IPdfDimensions } from '../../models/IPdfDimensions'
import { IGettingReqData } from '../../models/IGettingReqData'
import { InputValueType } from '../../models/InputValueType.type'

function QrRegeneration() {
    const [showLoader, setShowLoader] = useState(false)
    const [idLink, setIdLink] = useState<string>('')
    const [componentId, setComponentId] = useState<string>('')
    const [alertOpt, setAlertOpt] = useState({ isOpen: false, message: '', variant: '' })
    const [downloadOptions, setDownloadOptions] = useState<IDownloadOptions>(defaultDownloadOptions)
    const [pdfDimensions, setPdfDimensions] = useState({ pdfWidth: 35, pdfHeight: 14 })
    const [listOfIdLinks, setIdLinks] = useState<string[] | undefined>();
    const [isExcelFile, setIsExcelFile] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [selectedFile, setSelectedFile] = useState<any>('');

    // Process ref is used to prevent multiple requests
    const processRef = useRef(false)

    const isButtonDisabled = () => {
        const isOptionChosen = downloadOptions.excel || downloadOptions.png || downloadOptions.pdf;
        const hasValidIdLinkOrComponentId = idLink !== '' || componentId !== '';
        const hasValidIdLinkList = isExcelFile && listOfIdLinks && listOfIdLinks.length >= 1;

        return !(isOptionChosen && (hasValidIdLinkOrComponentId || hasValidIdLinkList));
    }

    const onInputValueChange = (type: InputValueType, value: string) => {
        if (type === "idLink") {
            setIdLink(value)
        } else if (type === "componentId") {
            setComponentId(value)
        }
    }
    const dimensionWarning = (message: string) => {
        setAlertOpt({ isOpen: true, message: message, variant: 'warning' })
        setTimeout(() => {
            setAlertOpt({ isOpen: false, message: '', variant: alertOpt.variant })
        }, 5000)
    }

    const downloadFile = async (fileType: string) => {
        setShowLoader(true)
        let requestData: IQrRegenerate = {
            idLink: idLink ? [idLink] : [],
            componentId: componentId,
            pdfDimensions: { pdfWidth: pdfDimensions.pdfWidth / 10, pdfHeight: pdfDimensions.pdfHeight / 10 },
            downloadOptions: downloadOptions
        }
        if (listOfIdLinks) {
            requestData["idLink"] = listOfIdLinks
        }

        if (requestData.idLink && requestData.idLink.length < 1) {
            delete requestData.idLink
        }

        if (!componentId) {
            delete requestData.componentId
        }

        try {
            const result = await new HttpRequests().qrRegenerate(requestData)
            setSuccessOptions(result, fileType)
        }
        catch (error) {
            const failOptProps = { error, option: fileType, setAlertOpt, alertOpt }
            setFailOptions(failOptProps)
        }
        resetExcelFile()
        setShowLoader(false)
        setIdLink('')
        setComponentId('')
        setDownloadOptions(defaultDownloadOptions)
        setPdfDimensions({ pdfWidth: 35, pdfHeight: 14 })
    }

    const setSuccessOptions = async (serverResponse: AxiosResponse<File, File>, fileType: string) => {
        if (serverResponse.data.type === ContentTypes.ZIP || serverResponse.data.type === ContentTypes.PDF || serverResponse.data.type === ContentTypes.EXCEL || serverResponse.data.type === ContentTypes.PNG) {
            downloadFileOnBrowser(serverResponse, fileType)
            setAlertOpt({ isOpen: true, message: GeneralTexts.successfullyDowndloaded, variant: "success" })
            setTimeout(() => {
                setAlertOpt({ isOpen: false, message: '', variant: alertOpt.variant })
            }, 3000)
        }
        else if (serverResponse.data.type === "application/json") {
            const { message } = JSON.parse(await serverResponse.data.text())
            const failOptProps = { error: message, option: fileType, setAlertOpt, alertOpt }
            setFailOptions(failOptProps)
        } else {
            const failOptProps = { error: GeneralTexts.sthWrong, option: 'zipFile', setAlertOpt, alertOpt }
            setFailOptions(failOptProps)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let isMultiple = false
        if (listOfIdLinks && listOfIdLinks.length > 1) {
            isMultiple = true
        }
        Utils.setDataTypeAndDownloadFile(
            pdfDimensions,
            dimensionWarning,
            downloadOptions,
            downloadFile,
            isMultiple,
            true)
    }

    const handleFileUpload = (event) => {
        setSelectedFile(event.target.value);
        const file = event.target.files[0];

        if (file) {
            setIsExcelFile(true);
            const reader = new FileReader();

            reader.onload = function (e) {
                const data = e.target!.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const listOfIdLinks = Utils.sheetDataToArray(sheet, "idLink");
                setIdLinks(listOfIdLinks);
            };

            reader.readAsBinaryString(file);
        }
    };

    const resetExcelFile = () => {
        setSelectedFile('')
        setIsExcelFile(false)
        setImageSrc('')
        setIdLinks(undefined)
    };

    const debounceTime = 300;
    const debouncedGetOverview = useCallback(
        debounce(async () => {
            setImageSrc('');
            const preViewDownloadOptions = getPreviewDownloadOptions(downloadOptions);
            const gettingReqDataProps: IGettingReqData = {
                idLink: idLink,
                pdfDimensions: pdfDimensions,
                preViewDownloadOptions: preViewDownloadOptions,
                listOfIdLinks: listOfIdLinks
            }
            const requestData = getRequestData(gettingReqDataProps);

            if (!isValidRequestData(requestData, pdfDimensions, idLink, listOfIdLinks)) {
                return;
            }

            processRef.current = true;
            try {
                const result = await new HttpRequests().qrRegenerate(requestData);
                if (result.data.type === "image/png") {
                    const url = URL.createObjectURL(result.data);
                    setImageSrc(url);
                }
            } catch (error) {
                console.log('error', error);
            }
            processRef.current = false;
        }, debounceTime),
        [pdfDimensions, idLink, listOfIdLinks, downloadOptions.dataMatrix, downloadOptions.rectangular, componentId]
    );

    function getPreviewDownloadOptions(downloadOptions: IDownloadOptions): IDownloadOptions {
        return {
            png: true,
            pdf: false,
            excel: false,
            rectangular: downloadOptions.rectangular,
            dataMatrix: downloadOptions.dataMatrix
        };
    }

    function getRequestData(gettingReqDataProps: IGettingReqData): IQrRegenerate {
        const { idLink, pdfDimensions, preViewDownloadOptions, listOfIdLinks } = gettingReqDataProps;
        const firstIdLink = listOfIdLinks && listOfIdLinks[0];
        const idLinks = firstIdLink ? [firstIdLink] : idLink ? [idLink] : [];

        let requestData: IQrRegenerate = {
            idLink: idLinks,
            componentId: componentId,
            pdfDimensions: {
                pdfWidth: pdfDimensions.pdfWidth / 10,
                pdfHeight: pdfDimensions.pdfHeight / 10
            },
            downloadOptions: preViewDownloadOptions
        };

        if (!idLinks.length) {
            delete requestData.idLink;
        }

        if (!componentId) {
            delete requestData.componentId;
        }

        return requestData;
    }

    function isValidRequestData(requestData: IQrRegenerate, pdfDimensions: IPdfDimensions, idLink: string, listOfIdLinks?: string[]): boolean {
        const isProcessValid = !processRef.current;
        const isDimensionsCorrect = Utils.isDimensionsCorrect(pdfDimensions);
        const isRatioCorrect = Utils.isRatioCorrect(pdfDimensions);
        const hasIdLinks = listOfIdLinks && listOfIdLinks.length >= 1;
        const isComponentIdValid = requestData.componentId;
        const isIdLinkOrComponentIdValid = Utils.isValidUrl(idLink) || isComponentIdValid;
        const isIdLinkValid = hasIdLinks ? true : isIdLinkOrComponentIdValid;
        const isFirstIdLinkValid = listOfIdLinks && Utils.isValidUrl(listOfIdLinks[0]);

        if (!isDimensionsCorrect || !isRatioCorrect) {
            setImageSrc('');
            return false;
        }

        if (isProcessValid && isDimensionsCorrect && isRatioCorrect && (isIdLinkValid || isFirstIdLinkValid || isComponentIdValid)) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        debouncedGetOverview();
        return () => {
            debouncedGetOverview.cancel();
        };
    }, [debouncedGetOverview]);

    return (
        <div className='regenerate_wrapper'>
            <AppHeader />

            <div style={{ display: showLoader ? "block" : "none", textAlign: "center" }}>
                <Loader />
            </div>

            <Alert className="id_alert" variant={alertOpt.variant} show={alertOpt.isOpen} >
                {alertOpt.message}
            </Alert>

            <QrRegenerationTitle />

            <Form onSubmit={handleSubmit} >
                <QrRegenerationInputPart
                    onInputValueChange={onInputValueChange}
                    // setIdLink={setIdLink}
                    idLink={idLink}
                    componentId={componentId}
                    // setComponentId={setComponentId}
                    downloadOptions={downloadOptions}
                    setDownloadOptions={setDownloadOptions}
                    pdfDimensions={pdfDimensions}
                    setPdfDimensions={setPdfDimensions}
                    disabled={showLoader}
                    isExcelFile={isExcelFile}
                    handleFileUpload={handleFileUpload}
                    imageSrc={imageSrc}
                    selectedFile={selectedFile}
                    resetExcelFile={resetExcelFile}
                />

                <div className="id_button_group">
                    <Button
                        data-testid="create"
                        type="submit"
                        className='general_button'
                        disabled={isButtonDisabled()}
                    > {GeneralTexts.generate}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default QrRegeneration
