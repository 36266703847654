import { GeneralTexts } from '../../language/Texts';
import { IDownloadOptions } from '../../models/IDownloadOptions';
import SwitchInputPart from './SwitchInputPart';
import './SwitchButton.css';

interface SwitchButtonProps {
    handleDownloadOptions: (option: string) => void,
    downloadOptions: IDownloadOptions,
}

function SwitchButton({ handleDownloadOptions, downloadOptions }: SwitchButtonProps) {

    return (
        <div className="switch_wrapper">
            <SwitchInputPart
                firstSwitchText={GeneralTexts.QRCode}
                secondSwitchText={GeneralTexts.dataMatrixCode}
                isChecked={downloadOptions.dataMatrix}
                handleDownloadOptions={handleDownloadOptions}
                downloadOptions={downloadOptions}
                handleDownloadOptionParameter='dataMatrix'
            />
            {
                downloadOptions.dataMatrix &&
                <SwitchInputPart
                    firstSwitchText={GeneralTexts.square}
                    secondSwitchText={GeneralTexts.rectangular}
                    isChecked={downloadOptions.rectangular}
                    handleDownloadOptions={handleDownloadOptions}
                    downloadOptions={downloadOptions}
                    handleDownloadOptionParameter='rectangular'
                />}
        </div >
    );
}

export default SwitchButton;
