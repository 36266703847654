import React, { useRef, useState } from 'react';
import { UpdateManufacturerPage } from '../../language/Texts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

interface UploadProps {
    manufacturerIcon: File | undefined,
    setManufacturerIcon: (icon: File | undefined) => void,
    savedIcon: string | null,
    setSavedIcon: (icon: string | null) => void
}

function ManufacturerImageUpload({ setManufacturerIcon, manufacturerIcon, savedIcon, setSavedIcon }: UploadProps) {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files && event.target.files.length > 0 && event.target.files[0];
        if (file) {
            setManufacturerIcon(event.target.files![0]);
        }
    };

    const openFileDialog = () => {
        if (inputRef.current) {
            inputRef.current.click(); // Trigger the file dialog
        }
    };

    const removeSelectedFile = () => {
        setManufacturerIcon(undefined);
        // if there is no manufacturer icon in db, it is setted as null
        // if user delete saved icon, we set it to null that is why.
        // But it is sent to backend as string 'null', because of formData
        setSavedIcon(null);
    };

    const showDeleteIcon = () => {
        if (manufacturerIcon?.name || savedIcon) {
            return <FontAwesomeIcon
                data-testid='remove_file_icon'
                onClick={() => removeSelectedFile()}
                style={{ float: 'right' }}
                className="remove_file_icon" icon={faTrashCan} />
        }
    }

    return (
        <div className='input_group'>
            <label className='input_label'>{UpdateManufacturerPage.image}</label>
            <div className="image_input_wrapper">
                <input
                    data-testid='upload_image'
                    ref={inputRef}
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                />
                <button
                    type='button'
                    className='select_file_button'
                    onClick={openFileDialog}
                >
                    {UpdateManufacturerPage.chooseFileButton}
                </button>
                <span className='selected_file_name'>
                    {manufacturerIcon?.name ?? savedIcon ?? UpdateManufacturerPage.nothingChoosen}
                </span>
                {showDeleteIcon()}

            </div>
        </div>
    )
}

export default ManufacturerImageUpload