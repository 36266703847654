import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import CustomModal from '../../components/customModal/CustomModal';
import AppHeader from '../../components/header/AppHeader';
import InputPart from '../../components/inputPart/InputPart';
import { GeneralTexts, ManufacturerPage, UpdateManufacturerPage } from '../../language/Texts';
import { IBackendResult } from '../../models/IBackendResult';
import { IManufacturerItem } from '../../models/IManufacturerList';
import { IPutData } from '../../models/IPutData';
import { IUserRight } from '../../models/IUserRight';
import { Paths } from '../../models/Paths';
import { createFormData } from '../../utils/CreateFormData';
import { HttpRequests } from '../../utils/HttpRequests';
import AddUser from './AddUser';
import ManufacturerImageUpload from './ManufacturerImageUpload';
import UpdateButton from './UpdateButton';
import './UpdateManufacturer.css';

function UpdateManufacturer({ getManufacturerList }) {
  const [alertOpt, setAlertOpt] = useState({ isOpen: false, message: '', variant: '' })
  const [disabled, setDisabled] = useState(false)
  const [modifyData, setmodifyData] = useState<IManufacturerItem>();
  const [manufacturerIcon, setManufacturerIcon] = useState<File>();
  const { state } = useLocation() as unknown as {
    state: {
      manufacturerItem: IManufacturerItem,
      isCreate: boolean,
      isFromOverview: boolean
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isDeleteRef = useRef(false);
  const navigate = useNavigate();
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }
  // If the page is refreshed, useLocation is deleted. That's why it is saved under sessionstorage.
  if (state) {
    sessionStorage.setItem("manufacturerItem", JSON.stringify(state.manufacturerItem))
    sessionStorage.setItem("isCreate", JSON.stringify(state.isCreate))
    sessionStorage.setItem("isFromOverview", JSON.stringify(state.isFromOverview))
  }
  // in order to change titel and process for creation of a new manufacturer, we use isCreate
  const isCreate = state ? state.isCreate : JSON.parse(sessionStorage.getItem("isCreate")!)

  const manufacturerItem = state ? state.manufacturerItem : JSON.parse(sessionStorage.getItem("manufacturerItem")!)
  const readyUserList = manufacturerItem?.user ? manufacturerItem.user : []
  const [userList, setUserList] = useState<IUserRight[]>(readyUserList)

  const { trusted_manufacturer_id, trusted_manufacturer_name, counter, basis_url, image } = state ? state.manufacturerItem : JSON.parse(sessionStorage.getItem("manufacturerItem")!);
  const [url, setUrl] = useState<string>(basis_url)
  const [savedIcon, setSavedIcon] = useState(image)

  const navigatePage = (path: string, update?: boolean) => {
    navigate(path, { state: update })
  }

  // if user comes from create page and doesn't want to add manufacturer, manufacturer is deleted
  const deleteManufacturer = async (): Promise<{ success: boolean }> => {
    try {
      await new HttpRequests().deleteManufacturer(trusted_manufacturer_id)
      return { success: true }
    } catch (error) {
      setAlertOpt({ isOpen: true, message: `${GeneralTexts.failedToDeleteManufacturer} : ` + trusted_manufacturer_name, variant: 'danger' })
      console.error('error on deleting manufacturer', error);
      return { success: false }
    }
  }

  const cancelHandel = async () => {
    isDeleteRef.current = true
    if (isCreate) {
      const deleteItem = await deleteManufacturer()
      if (deleteItem.success) {
        navigatePage(Paths.trustedmanufacturers, true)
      } else {
        setTimeout(() => {
          setAlertOpt({ isOpen: false, message: alertOpt.message, variant: alertOpt.variant })
          navigatePage(Paths.trustedmanufacturers, true)
        }, 2000)
      }
      return
    }
    navigatePage(Paths.overview)
  }

  const putData: IPutData = {
    manufacturerIcon,
    trusted_manufacturer_id,
    trusted_manufacturer_name,
    counter,
    user_list: userList,
    basis_url: url?.toLowerCase(),
    image:savedIcon
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    isDeleteRef.current = true
    try {
      setDisabled(true)
      const formData = createFormData(putData)
      let result = await new HttpRequests().updateManufacturer(formData)
      setSuccessOptions(result)
    } catch (error) {
      setDisabled(true)
      setAlertOpt({ isOpen: true, message: GeneralTexts.failed, variant: 'danger' })
      console.log('error on updating data: ', error);
    }
    setTimeout(() => {
      setAlertOpt({ isOpen: false, message: alertOpt.message, variant: alertOpt.variant })
      setDisabled(false)
    }, 2000)
  };

  const setSuccessOptions = (result: IBackendResult) => {
    if (result.success) {
      getManufacturerList()
      navigatePage(Paths.trustedmanufacturers, false)
    } else {
      setAlertOpt({ isOpen: true, message: GeneralTexts.failed, variant: 'danger' })
      console.log('error on updating data: ', result.error);
    }
  }

  const onChange = (e) => {
    setUrl(e.target.value)
  }

  useEffect(() => {
    // returned function will be called on component unmount 
    return () => {
      if (isDeleteRef.current === false && isCreate) {
        deleteManufacturer()
      }
    }
  }, [])

  return (
    <div className='update_wrapper'>
      <AppHeader />
      <Alert className="alert_error" variant={alertOpt.variant} show={alertOpt.isOpen} >
        {alertOpt.message}
      </Alert>

      <div className="update_title-button">
        <h4 className="box_title">
          {isCreate ? UpdateManufacturerPage.importManufacturer : UpdateManufacturerPage.editManufacturer}
        </h4>
        <div>
          <Button
            onClick={() => cancelHandel()}
            variant="primary"
            className="cancel_button general_button">{GeneralTexts.cancel}
          </Button>
        </div>
      </div>

      <form data-testid="form" onSubmit={handleSubmit} >
        <div className="update_form">
          <div className="update_part">
            <InputPart labelText={ManufacturerPage.manufacturerName} type="text" value={trusted_manufacturer_name} disabled={true} required={true} />
            <InputPart labelText={UpdateManufacturerPage.manufacturerId} type="text" value={trusted_manufacturer_id} disabled={true} required={true} />
            <InputPart labelText={ManufacturerPage.counter} type="text" value={counter} disabled={true} required={true} />
            <InputPart labelText={UpdateManufacturerPage.baseUrl} type="url" value={url} disabled={false} required={true} onChange={onChange} />
            <ManufacturerImageUpload setManufacturerIcon={setManufacturerIcon} manufacturerIcon={manufacturerIcon} savedIcon={savedIcon} setSavedIcon={setSavedIcon} />
          </div>
          {isCreate ? null :
            <AddUser
              setUserList={setUserList}
              userList={userList}
              trusted_manufacturer_id={trusted_manufacturer_id}
            />}
        </div>
        <UpdateButton
          disabled={disabled}
        />
      </form>

      <CustomModal
        toggleModal={toggleModal}
        isModalOpen={isModalOpen}
        modifyData={modifyData!}
        getManufacturerList={getManufacturerList}
      />

    </div >
  )
}

export default UpdateManufacturer
