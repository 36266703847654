import { FooterTexts } from '../../language/Texts'
import { Texts } from '../../models/Texts'
import AppHeader from '../header/AppHeader'
import TextBox from '../textBox/TextBox'
import './Imprint.css'

function Imprint() {

    const editorTexts = [
        Texts.imprintEditorText1,
        Texts.imprintEditorText2,
        Texts.imprintEditorText3,
        Texts.imprintEditorText4,
        Texts.imprintEditorText5,
        Texts.imprintEditorText6,
        FooterTexts.editorText7,
        FooterTexts.editorText8,
        FooterTexts.editorText9,
        FooterTexts.editorText10,
        FooterTexts.editorText11
    ]

    const disclaimerText = [FooterTexts.disclaimerText]
    const copyrightText = [FooterTexts.copyrightText]

    return (
        <div className='impressum_wrapper'>
            <AppHeader />
            <h4 className="box_title">
                {FooterTexts.imprint}
            </h4>
            <TextBox text={editorTexts} title={FooterTexts.editorTitle} />
            <TextBox text={disclaimerText} title={FooterTexts.disclaimerTitle} />
            <TextBox text={copyrightText} title={FooterTexts.copyRightTitle} />
        </div >
    )
}

export default Imprint