import { faPencilAlt, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { IManufacturerItem } from '../../models/IManufacturerList'
import { GeneralTexts } from '../../language/Texts'
export interface IUpdateProps {
    isOnlyIcon: boolean
    manufacturerItem: IManufacturerItem
    toggleModal: () => void
    setmodifyData: (IManufacturerItem: IManufacturerItem) => void
}

function ButtonGroup({ isOnlyIcon, manufacturerItem, toggleModal, setmodifyData }: IUpdateProps) {
    let navigate = useNavigate()
    const iconActions = () => {
        setmodifyData(manufacturerItem)
        toggleModal()
    }

    return (
        <div className='overview_button_group'>
            <Button
                data-testid="update_button"
                onClick={() => navigate("/updatemanufacturer", { state: { manufacturerItem, isCreate: false, isFromOverview: true }, replace: false })}
                className="general_button">
                <FontAwesomeIcon icon={faPencilAlt} />
                {!isOnlyIcon && " " + GeneralTexts.edit}
            </Button>
            <Button
                data-testid="delete_button"
                onClick={iconActions}
                className="general_button delete_button" >
                <FontAwesomeIcon icon={faTrash} />
                {!isOnlyIcon && " " + GeneralTexts.delete}
            </Button>
        </div>
    )
}

export default ButtonGroup
