import { IPutData } from "../models/IPutData";

export const createFormData = (data: IPutData) => {

    const formData = new FormData();
    if (data.manufacturerIcon) {
        formData.append('file', data.manufacturerIcon, data.manufacturerIcon.name);
    }
    formData.append('trusted_manufacturer_id', data.trusted_manufacturer_id);
    formData.append('trusted_manufacturer_name', data.trusted_manufacturer_name);
    formData.append('counter', JSON.stringify(data.counter));
    formData.append('user_list', JSON.stringify(data.user_list));
    formData.append('basis_url', data.basis_url);
    formData.append('image', data.image);

    return formData;
}