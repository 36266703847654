import { useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { defaultDownloadOptions } from '../../components/DownloadOptionsController/DefaultDownloadOptions'
import AppHeader from '../../components/header/AppHeader'
import Loader from '../../components/loader/Loader'
import { GeneralTexts } from '../../language/Texts'
import { IDownloadOptions } from '../../models/IDownloadOptions'
import { IDownloadRequest } from '../../models/IDownloadRequest '
import { IManufacturerItem } from '../../models/IManufacturerList'
import { RetrofitManufacturer } from '../../models/RetrofitManufacturer'
import { HttpRequests } from '../../utils/HttpRequests'
import { setFailOptions, setSuccessOptions } from '../../utils/SetSuccessAndFailOptions'
import { Utils } from '../../utils/Utils'
import RetrofitInputPart from './RetrofitInputPart'
import RetrofitQrTitlePart from './RetrofitQrTitlePart'

interface SuccessVieweProps {
    manufacturerList: IManufacturerItem[] | undefined,
    getManufacturerList: () => void,
}

const CreateView = ({ manufacturerList, getManufacturerList, }: SuccessVieweProps) => {
    const [showLoader, setShowLoader] = useState(false)
    const [quantity, setQuantity] = useState<string>('')
    const [singleCustomText, setSingleCustomText] = useState<string>('')
    const [alertOpt, setAlertOpt] = useState({ isOpen: false, message: '', variant: '' })
    const [downloadOptions, setDownloadOptions] = useState<IDownloadOptions>(defaultDownloadOptions)
    const [pdfDimensions, setPdfDimensions] = useState({ pdfWidth: 35, pdfHeight: 14 })
    const [customTextList, setCustomTextList] = useState<string[]>([]);
    const [selectedFile, setSelectedFile] = useState<any>('');
    const [isExcelFile, setIsExcelFile] = useState(false)

    const isButtonDisabled = () => {
        const isAnyOption = downloadOptions.excel || downloadOptions.png || downloadOptions.pdf
        let hasQuantity = quantity && parseInt(quantity) > 0
        return !(hasQuantity && !showLoader && isAnyOption)
    }

    // The difference from idLink create is retrofit manufacturer. 
    // Hier only ids from retrofit manufacturer are downloaded.
    const retrofitManufacturer = manufacturerList?.filter((item) => {
        return item.trusted_manufacturer_id === RetrofitManufacturer.id
    })[0]

    const dimensionWarning = (message: string) => {
        setAlertOpt({ isOpen: true, message: message, variant: 'warning' })
        setTimeout(() => {
            setAlertOpt({ isOpen: false, message: '', variant: alertOpt.variant })
        }, 5000)
    }

    const downloadFile = async (fileType: string) => {
        setShowLoader(true)
        let requestData: IDownloadRequest = {
            manufacturer: retrofitManufacturer!,
            quantity: parseInt(quantity)!,
            pdfDimensions: { pdfWidth: pdfDimensions.pdfWidth / 10, pdfHeight: pdfDimensions.pdfHeight / 10 },
            downloadOptions: downloadOptions,
            customText: singleCustomText ? [singleCustomText] : customTextList,
            isCustomTextProvidedByExcel: isExcelFile
        }
        try {
            const result = await new HttpRequests().createIdLinksAndGetFile(requestData)
            const successOptProps = { serverResponse: result, fileType, setAlertOpt, alertOpt, downloadFile }
            setSuccessOptions(successOptProps)
        }
        catch (error) {
            const failOptProps = { error, option: fileType, setAlertOpt, alertOpt }
            setFailOptions(failOptProps)
        }
        reset()
    }

    const reset = () => {
        getManufacturerList()
        setShowLoader(false)
        setQuantity('')
        setSingleCustomText('')
        setDownloadOptions(defaultDownloadOptions)
        setPdfDimensions({ pdfWidth: 35, pdfHeight: 14 })
        setSelectedFile('')
        setCustomTextList([])
        setIsExcelFile(false)
    }

    return (
        <div className='retrofit_wrapper'>
            <AppHeader />

            <div style={{ display: showLoader ? "block" : "none", textAlign: "center" }}>
                <Loader />
            </div>

            <Alert className="id_alert" variant={alertOpt.variant} show={alertOpt.isOpen} >
                {alertOpt.message}
            </Alert>

            <RetrofitQrTitlePart />
            <Form>
                <RetrofitInputPart
                    setQuantity={setQuantity}
                    quantity={quantity}
                    singleCustomText={singleCustomText}
                    setSingleCustomText={setSingleCustomText}
                    downloadOptions={downloadOptions}
                    setDownloadOptions={setDownloadOptions}
                    pdfDimensions={pdfDimensions}
                    setPdfDimensions={setPdfDimensions}
                    disabled={showLoader}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    customTextList={customTextList}
                    setCustomTextList={setCustomTextList}
                    isExcelFile={isExcelFile}
                    setIsExcelFile={setIsExcelFile}
                />

                <div className="id_button_group">
                    <Button
                        data-testid="create"
                        type="reset"
                        className='general_button'
                        disabled={isButtonDisabled()}
                        onClick={() =>
                            Utils.setDataTypeAndDownloadFile(
                                pdfDimensions,
                                dimensionWarning,
                                downloadOptions,
                                downloadFile)}
                    > {GeneralTexts.generate}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default CreateView
