import { Table } from 'react-bootstrap';
import { UpdateManufacturerPage } from '../../language/Texts';
import { IUserRight } from '../../models/IUserRight';

interface IAddUserProps {
    userList: IUserRight[]
}
function UserList({ userList }: IAddUserProps) {

    return (
        <div className="overview_user_list">
            <h4 className="box_text_title">
                {UpdateManufacturerPage.assignedUser}
            </h4>
            {userList?.length > 0 ?
                <div className="overview_email_table">
                    <Table striped borderless hover >
                        <thead>
                            <tr>
                                <th className='head_item'>E-Mail</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userList?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='body_item'>{item.username}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                : null}

        </div>
    )
}

export default UserList
