import { Alert } from 'react-bootstrap';
import Loader from '../../components/loader/Loader';
import { IdLinkCreatePage } from '../../language/Texts';

interface TitleProps {
    showLoader: boolean,
    alertOpt: {
        isOpen: boolean;
        message: string;
        variant: string;
    }
}

function HistoryTitlePart({ showLoader, alertOpt }: TitleProps) {
    return (
        <div>
            <div style={{ display: showLoader ? "block" : "none", textAlign: "center" }}>
                <Loader />
            </div>
            <Alert className="id_alert" variant={alertOpt.variant} show={alertOpt.isOpen} >
                {alertOpt.message}
            </Alert>
            <div className="history_title">
                <h4 className="box_text_title">
                    {IdLinkCreatePage.history}
                </h4>
                <p className="id_text">
                    {IdLinkCreatePage.historyTitle}
                </p>
            </div>
        </div>
    )
}

export default HistoryTitlePart
