import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import { Form } from "react-bootstrap";
import XLSX from 'xlsx';
import CustomTextInput from "../../components/customTextInput/CustomTextInput";
import DownloadOptions from "../../components/downloadOptions/DownloadOptions";
import { GeneralTexts, QrRecreatePage, RetrofitCreatePage } from "../../language/Texts";
import { IDownloadOptions } from "../../models/IDownloadOptions";
import { IPdfDimensions } from "../../models/IPdfDimensions";
import { Utils } from "../../utils/Utils";

interface InputPartProps {
    quantity: string;
    setQuantity: (value: string) => void;
    singleCustomText: string;
    setSingleCustomText: (value: string) => void;
    downloadOptions: IDownloadOptions;
    setDownloadOptions: (downloadOptions: IDownloadOptions) => void;
    pdfDimensions: IPdfDimensions;
    setPdfDimensions: ({ pdfWidth, pdfHeight }) => void;
    selectedFile: string;
    setSelectedFile: (value: string) => void;
    isExcelFile: boolean;
    setIsExcelFile: (value: boolean) => void;
    customTextList: string[];
    setCustomTextList: (value: string[]) => void;
    disabled: boolean;
}

const RetrofitInputPart = ({
    quantity, setQuantity,
    singleCustomText, setSingleCustomText,
    downloadOptions, setDownloadOptions,
    pdfDimensions, setPdfDimensions,
    selectedFile, setSelectedFile,
    isExcelFile, setIsExcelFile,
    setCustomTextList,
    disabled
}: InputPartProps) => {

    const setIdQuantity = (quantity: string) => {
        setQuantity(quantity);
    }

    const handleCustomTextInput = (value: string) => {
        setSingleCustomText(value);
    }

    const handleDownloadOptions = useCallback((option: string) => {
        Utils.handleDownloadOptions(option, downloadOptions, setDownloadOptions);
    }, [downloadOptions, setDownloadOptions]);

    const handleFileUpload = useCallback((event) => {
        setSelectedFile(event.target.value);
        const file = event.target.files[0];

        if (!file) return;

        setIsExcelFile(true);
        const reader = new FileReader();

        reader.onload = function (e) {
            const data = e.target!.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const listOfCustomTexts = Utils.sheetDataToArray(sheet, "name");
            setCustomTextList(listOfCustomTexts);
            setQuantity(listOfCustomTexts.length.toString());
        };
        reader.readAsBinaryString(file);

    }, [setSelectedFile, setIsExcelFile, setCustomTextList, setQuantity]);

    const resetExcelFile = useCallback(() => {
        setSelectedFile('');
        setIsExcelFile(false);
        setCustomTextList([]);
        setQuantity('');
    }, [setSelectedFile, setIsExcelFile, setCustomTextList, setQuantity]);

    return (
        <div className="id_input_part">
            <div className="quantity_text_wrapper">
                <div className="id_label">
                    <label className='id_label'>{RetrofitCreatePage.quantityOftheLinks}</label>
                    <input
                        value={quantity}
                        data-testid="quantity"
                        placeholder={GeneralTexts.quantity}
                        className='id_number_input'
                        type="number"
                        inputMode="numeric"
                        onChange={(e) => setIdQuantity(e.target.value)}
                        disabled={disabled}
                    />
                </div>

                <div className="text_input_wrapper">
                    <CustomTextInput
                        customText={singleCustomText}
                        handleCustomTextInput={handleCustomTextInput}
                        disabled={disabled || isExcelFile}
                    />
                    <div className='seperator_text'>{GeneralTexts.or}</div>
                    <Form.Group controlId="formFileSm" className="id_label">
                        <Form.Label className='id_label'>{QrRecreatePage.importExcelFile}</Form.Label>
                        <Form.Control
                            data-testid='file-input'
                            className='id_number_input retrofit_excel'
                            disabled={disabled || singleCustomText !== ''}
                            accept=".xlsx"
                            type="file"
                            value={selectedFile}
                            onChange={handleFileUpload}
                        />
                        {selectedFile &&
                            <FontAwesomeIcon
                                onClick={resetExcelFile}
                                className="remove_file_icon" icon={faTrashCan} />
                        }
                    </Form.Group>
                </div>
            </div>
            <DownloadOptions
                handleDownloadOptions={handleDownloadOptions}
                downloadOptions={downloadOptions}
                pdfDimensions={pdfDimensions}
                setPdfDimensions={setPdfDimensions}
                disabled={disabled} />
        </div>
    );
};

export default RetrofitInputPart;