import { createContext, useContext } from "react"

export type GlobalContent = {
    totalManufacturerPages: number
    setTotalManufacturerPages: (t: number) => void
    manufacturerPage: number
    setManufacturerPage: (p: number) => void
    totalHistoryPages: number
    setTotalHistoryPages: (t: number) => void
    historyPage: number
    setHistoryPage: (p: number) => void
}
export const GlobalContext = createContext<GlobalContent>({
    totalManufacturerPages: 0,
    setTotalManufacturerPages: () => { },
    manufacturerPage: 0,
    setManufacturerPage: () => { },
    totalHistoryPages: 0,
    setTotalHistoryPages: () => { },
    historyPage: 0,
    setHistoryPage: () => { },
})
export const useGlobalContext = () => useContext(GlobalContext)
