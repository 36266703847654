import DeleteForm from '../deleteForm/DeleteForm';
import Modal from 'react-bootstrap/Modal';
import { DataTestIds } from '../../models/DataTestIDs';
import { GeneralTexts } from '../../language/Texts';

function CustomModal({ toggleModal, isModalOpen, modifyData, getManufacturerList }) {

    return (
        <>
            <Modal data-testid={DataTestIds.customModalWrapper} show={isModalOpen} >
                <Modal.Body className='modal_header' >{GeneralTexts.delete}</Modal.Body>
                <Modal.Body className='modal_title' >{GeneralTexts.deleteText}</Modal.Body>
                <Modal.Footer className='modal_title' ><DeleteForm toggleModal={toggleModal} modifyData={modifyData} getManufacturerList={getManufacturerList} /> </Modal.Footer>
            </Modal>
        </>
    );
}
export default CustomModal
