import React from 'react'
import "./AppHeader.css"
import logo from '../../assets/conexo.png'

function AppHeader() {
    return (
        <div className='header_wrapper'>
            <img className="logo" src={logo} alt="conexo-logo" />
            <p className='icon_text'>IdentificationLinkGenerator</p>
            <p className='icon_border'></p>
        </div>
    )
}

export default AppHeader
